import React from 'react'
import RestaurantDriverSwitch from './RestaurantDriverSwitch'
import {
  PAST_CUTOFF_EVENT,
  // LATE_DELIVERY_EVENT,
  BACK_EVENT
} from '../../utils/statemachine'
import { FaArrowAltCircleLeft, FaUndoAlt } from 'react-icons/fa'

const DemoStateUpdates = ({ triggerEvent, reset, nextState }) => (
  <div className="demo-control-wrapper">
    <div className="demo-header">
      <div className="col-xs-2 demo-title">DEMO CONTROLS</div>
      <div className="col-xs-10 white-space" />
    </div>
    <div className="demo-controls">
      <div className="col-xs-8">
        <div className="col-xs-8">
          <span className="demo-btn">
            <span className="left">
              <FaUndoAlt onClick={reset} />
            </span>
            <span className="right">
              <FaArrowAltCircleLeft onClick={() => triggerEvent(BACK_EVENT)} />
            </span>
          </span>
          <span className="phase">
            Next Step: <b>{nextState}</b>
          </span>
        </div>
        <div className="col-xs-4">
          <div
            className="cutoff-btn"
            onClick={() => triggerEvent(PAST_CUTOFF_EVENT)}
          >
            <span>
              <i className="icon-clock" />&nbsp;
              Simulate Past Cutoff
            </span>
          </div>
        </div>
      </div>
      <div className="col-xs-4 switch">
        <RestaurantDriverSwitch />
      </div>
    </div>
    <style jsx>{`
      @import '@foodsby/nutrient/src/css/vars.css';
      .demo-control-wrapper {
        width: 1200px;
      }

      .demo-header {
        text-align: center;
        & > .demo-title {
          background-color: #fcf3cf;
          font-weight: 500;
          border: 1px solid rgba(20, 20, 20, 0.05);
        }
      }

      .demo-controls {
        padding: var(--gutter);
        background: #fcf3cf;
        border-radius: 2px;
        box-shadow: 10px 10px 20px 8px rgba(20, 20, 20, 0.05);
        border: 1px solid rgba(20, 20, 20, 0.05);
        margin-bottom: 12px;

        & .phase {
          margin: 24px 12px 0px;
          font-size: 20px;
        }
      }

      .cutoff-btn {
        vertical-align: middle;
        text-align: center;
        width: 230px;
        background-color: var(--nu-tertiary);
        border: none;
        border-radius: var(--nu-radius);
        cursor: pointer;
        font-size: 22px;
        color: white;

        &:hover {
          background-color: white;
          color: var(--nu-tertiary);
        }
      }

      .demo-btn {
        vertical-align: middle;
        font-size: 24px;
        color: var(--nu-tertiary);

        & > .left {
          &:hover {
            cursor: pointer;
            color: var(--nu-tertiary-alt);
          }
        }

        & > .right {
          margin-left: 12px;
          &:hover {
            cursor: pointer;
            color: var(--nu-tertiary-alt);
          }
        }
      }

      .switch {
        border-left: 2px solid var(--nu-light-grey);
        padding-left: 10px;
      }
    `}</style>
  </div>
)

export default DemoStateUpdates
