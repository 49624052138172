import React from 'react'

const OrderDetail = ({ order, orderNo }) => (
  <div className="wrapper">
    <div className="row top-row">
      <div className="col-xs-4 start-xs">#: {orderNo}</div>
      <div className="col-xs-8 end-xs">{order.name}</div>
    </div>
    <div className="row bottom-row">
      <div className="col-xs-4 start-xs">Meals: 1</div>
      <div className="col-xs-8 end-xs">Ph: {order.phone}</div>
    </div>
    <style jsx>{`
        .wrapper {
            margin: var(--gutter) 0px;
            font-size: 18px;
        }    

        .top-row {
            font-weight: bold;
        }

        .bottom-row {
            font-size: 16px;
        }
    `}</style>
  </div>
)

export default OrderDetail
