import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { mainRoutes } from '../../routes'
import { AppContext } from '../../context/AppContext'

class Route extends PureComponent {
  render() {
    const { active, children, className, route } = this.props
    return (
      <Link to={route}>
        <button className={active ? `${className} active` : className}>
          {children}
        </button>
        <style jsx>{`
          @import '@foodsby/nutrient/src/css/vars.css';
          .switch-button {
            background-color: white;
            border: none;
            cursor: pointer;
            font-size: 22px;
            color: var(--nu-secondary);

            &:hover {
              background-color: var(--nu-light-grey);
            }

            &.active {
              background-color: var(--nu-secondary);
              color: white;
            }
          }
        `}</style>
      </Link>
    )
  }
}

const RestaurantDriverSwitch = () => (
  <nav>
    <div className="mainRoutes">
      <span className="role-title">Role:</span>&nbsp;&nbsp;
      <Route
        route={mainRoutes.RESTAURANT}
        active={window.location.pathname === mainRoutes.RESTAURANT}
        className="switch-button"
      >
        <i className="icon icon-office" /> Restaurant Dashboard
      </Route>
      <Route
        route={mainRoutes.DRIVER}
        active={window.location.pathname === mainRoutes.DRIVER}
        className="switch-button"
      >
        <i className="icon icon-user" /> Driver
      </Route>
    </div>
    <style jsx>{`
      @import '@foodsby/nutrient/src/css/vars.css';
      nav {
      }
      .role-title {
        font-weight: 700;
        font-size: 20px;
      }
    `}</style>
  </nav>
)

const HOC = () => (
  <AppContext.Consumer>
    {context => <RestaurantDriverSwitch {...context} />}
  </AppContext.Consumer>
)

export default HOC
