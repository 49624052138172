import api from '../utils/api'
import { storeTokens, getRefreshToken } from '../services/jwt'

export const NO_REFRESH_TOKEN_FOUND = 'No refresh token found.'

export const LOGIN_URL = `/api/v1/uaa/oauth/token`
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID
const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET

export const signIn = async ({ username, password }) => {
  const formData = new FormData()
  formData.append('grant_type', 'password')
  formData.append('scope', 'admin')
  formData.append('username', username)
  formData.append('password', password)

  const data = await api.post(LOGIN_URL, formData, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    auth: {
      username: CLIENT_ID,
      password: CLIENT_SECRET
    }
  })

  storeTokens(data)
  return data
}

export const refreshToken = async () => {
  const token = getRefreshToken()
  if (token) {
    let formData = new FormData()
    formData.append('grant_type', 'refresh_token')
    formData.append('refresh_token', token)

    const data = await api.post(LOGIN_URL, formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      auth: {
        username: CLIENT_ID,
        password: CLIENT_SECRET
      }
    })
    storeTokens(data)
    return data
  } else {
    // this will trigger refresh failure which is desired
    throw Error(NO_REFRESH_TOKEN_FOUND)
  }
}
