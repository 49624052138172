import React, { PureComponent } from 'react'

class PdfTab extends PureComponent {
  render() {
    const { file } = this.props

    return (
      <div className="wrapper">
        <iframe src={file} height={1200} width={900} title="Documents" />
        <style jsx>{`
          .wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
        `}</style>
      </div>
    )
  }
}

export default PdfTab
