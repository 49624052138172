import React from 'react'

const Main = ({ children }) => (
  <main>
    {children}
    <style jsx>{`
      @import '@foodsby/nutrient/src/css/vars.css';
      main {
        padding: calc(2 * var(--gutter));
        width: 100%;
      }
    `}</style>
  </main>
)

export default Main
