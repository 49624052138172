import React from 'react'

const LoadingSpinner = ({ loading = true, spinnerClass }) =>
  loading && (
    <div className="wrapper">
      <div className={spinnerClass}>
        <div className="loader" />
      </div>
      <style jsx>{`
        .wrapper {
          width: 100%;
        }

        .loader {
          margin: 24px auto;
          border: 16px solid #f3f3f3;
          border-radius: 50%;
          border-top: 16px solid var(--nu-secondary);
          width: 120px;
          height: 120px;
          animation: spin 2s linear infinite;
        }

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      `}</style>
    </div>
  )

export default LoadingSpinner
