import React, { PureComponent } from 'react'
import HomeScreen from './HomeScreen'
import DeliveryDetailScreen from './DeliveryDetailScreen'
import DriverTextMessage from './DriverTextMessage'

class DriverApp extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      initialScreen: true,
      viewing: null,
      drop: () => {},
      lastDrop: null,
      next: () => {}
    }
  }

  // Yes this is a hack. Please forgive me.
  startViewing = row => {
    row === this.props.top
      ? this.startViewingTop()
      : row === this.props.middle
      ? this.startViewingMiddle()
      : this.startViewingBottom()
  }

  startViewingTop = () => {
    this.setState({
      viewing: 'top',
      drop: this.props.topDeliveryDropped,
      lastDrop: false,
      next: this.startViewingMiddle
    })
  }

  startViewingMiddle = () => {
    this.setState({
      viewing: 'middle',
      drop: this.props.middleDeliveryDropped,
      lastDrop: false,
      next: this.startViewingBottom
    })
  }

  startViewingBottom = () => {
    this.setState({
      viewing: 'bottom',
      drop: this.props.bottomDeliveryDropped,
      lastDrop: true,
      next: () =>
        console.log(
          "Looks like you're trying to view the next drop, but it's the last."
        )
    })
  }

  stopViewing = () => this.setState({ viewing: null })

  render() {
    const {
      driver,
      top,
      middle,
      bottom,
      startDelivering,
      passedCutOff,
      outForDelivery,
      clickLink,
      linkClicked
    } = this.props
    return (
      <div className="mobile-screen">
        {!linkClicked ? (
          <DriverTextMessage clickLink={clickLink} />
        ) : (
          <div>
            {this.state.viewing ? (
              <DeliveryDetailScreen
                row={this.props[this.state.viewing]}
                confirmDrop={this.state.drop}
                back={this.stopViewing}
                next={this.state.next}
                outForDelivery={outForDelivery}
                lastDrop={this.state.lastDrop}
              />
            ) : (
              <HomeScreen
                driver={driver}
                top={top}
                middle={middle}
                bottom={bottom}
                startDelivering={startDelivering}
                passedCutOff={passedCutOff}
                outForDelivery={outForDelivery}
                startViewing={this.startViewing}
              />
            )}
          </div>
        )}

        <style jsx global>{`
          .mobile-screen {
            overflow-y: auto;
            margin-top: 12px;
            background-color: #2c3e50;

            position: relative;
            width: 400px;
            height: 780px;
            margin: auto;
            margin-top: 24px;
            border: 16px black solid;
            border-top-width: 60px;
            border-bottom-width: 60px;
            border-radius: 36px;
            box-shadow: 10px 10px 20px 8px rgba(20, 20, 20, 0.05);
          }

          .cursor-pointer {
            cursor: pointer;
          }

          .white-text {
            color: white;
          }
        `}</style>
      </div>
    )
  }
}

export default DriverApp
