import React from 'react'
import { format } from 'date-fns'
import { Button } from '@foodsby/nutrient'
import DriverDeliveryDetail from './DriverDeliveryDetail'
import Questions from './Questions'

const HomeScreen = ({
  driver,
  top,
  middle,
  bottom,
  startDelivering,
  passedCutOff,
  outForDelivery,
  startViewing
}) => (
  <div className="wrapper">
    <div className="white-text">
      <h4>
        {driver.name}, you're driving for The Golden Spoon - Minneapolis, MN
      </h4>
      <Questions />
      <h5>
        3 Drops | {top.totalCount + middle.totalCount + bottom.totalCount} Meals
      </h5>
      {outForDelivery ? (
        <h6>Left for delivery at {format(outForDelivery, 'h:mm A')}</h6>
      ) : (
        <Button onClick={startDelivering} disabled={!passedCutOff}>
          OUT FOR DELIVERY
        </Button>
      )}
    </div>
    {top && (
      <DriverDeliveryDetail data={top} onClick={() => startViewing(top)} />
    )}
    {middle && (
      <DriverDeliveryDetail
        data={middle}
        onClick={() => startViewing(middle)}
      />
    )}
    {bottom && (
      <DriverDeliveryDetail
        data={bottom}
        onClick={() => startViewing(bottom)}
      />
    )}
    <style jsx>{`
      .wrapper {
        padding: 24px;
      }
    `}</style>
  </div>
)

export default HomeScreen
