import React, { PureComponent } from 'react'
import { AppContext } from '../context/AppContext'
import {
  TOP_DELIVERY_DROPPED_EVENT,
  MIDDLE_DELIVERY_DROPPED_EVENT,
  BOTTOM_DELIVERY_DROPPED_EVENT,
  OUT_FOR_DELIVERY_EVENT
} from '../utils/statemachine'
import DemoStateUpdates from '../components/common/DemoStateUpdates'
import DriverApp from '../components/driver/DriverApp'

class Drivers extends PureComponent {
  constructor(props, context) {
    super(props, context)
    this.props.subscribeToStateMachine()

    this.state = {
      clickedLink: false
    }
  }

  triggerEvent = EVENT_NAME => {
    this.props.machineEvent(EVENT_NAME)
  }

  clickLink = () => this.props.driverClickLink()

  render() {
    const {
      nextState,
      driver,
      top,
      middle,
      bottom,
      passedCutOff,
      outForDelivery,
      linkClicked
    } = this.props
    return (
      <div className="wrapper">
        <DemoStateUpdates
          nextState={nextState}
          triggerEvent={this.triggerEvent}
          reset={this.props.machineReset}
        />
        {!driver ? (
          <h3>No Driver Assigned</h3>
        ) : (
          <DriverApp
            driver={driver}
            top={top}
            middle={middle}
            bottom={bottom}
            passedCutOff={passedCutOff}
            clickLink={this.clickLink}
            linkClicked={linkClicked}
            outForDelivery={outForDelivery}
            startDelivering={() => this.triggerEvent(OUT_FOR_DELIVERY_EVENT)}
            topDeliveryDropped={() =>
              this.triggerEvent(TOP_DELIVERY_DROPPED_EVENT)
            }
            middleDeliveryDropped={() =>
              this.triggerEvent(MIDDLE_DELIVERY_DROPPED_EVENT)
            }
            bottomDeliveryDropped={() =>
              this.triggerEvent(BOTTOM_DELIVERY_DROPPED_EVENT)
            }
          />
        )}
        <style jsx>{`
          .wrapper {
            width: 1200px;
            display: flex;
            flex-direction: column;

            & > h3 {
              margin: auto;
              margin-top: 24px;
            }
          }
        `}</style>
      </div>
    )
  }
}

const HOC = props => (
  <AppContext.Consumer>
    {context => <Drivers {...props} {...context} />}
  </AppContext.Consumer>
)

export default HOC
