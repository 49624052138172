import menu from './menu.json'
import deliveries from './deliveries'

var machina = require('machina')

const START_EVENT = 'START'
const BACK_EVENT = 'BACK'

const DRIVER_CREATED_EVENT = 'DRIVER_CREATED'

const VIEW_BOTTOM_DELIVERY_EVENT = 'VIEW_BOTTOM_DELIVERY'
const VIEW_MIDDLE_DELIVERY_EVENT = 'VIEW_MIDDLE_DELIVERY'
const VIEW_TOP_DELIVERY_EVENT = 'VIEW_TOP_DELIVERY'
const VIEW_ALL_DELIVERY_EVENT = 'VIEW_ALL_DELIVERY'

const PAST_CUTOFF_EVENT = 'PAST_CUTOFF'

const OUT_FOR_DELIVERY_EVENT = 'OUT_FOR_DELIVERY'

const TOP_DELIVERY_DROPPED_EVENT = 'TOP_DELIVERY_DROPPED'
const MIDDLE_DELIVERY_DROPPED_EVENT = 'MIDDLE_DELIVERY_DROPPED'
const BOTTOM_DELIVERY_DROPPED_EVENT = 'BOTTOM_DELIVERY_DROPPED'
const LATE_DELIVERY_EVENT = 'LATE_DELIVERY'

const STATE_NAME = 'appState'

// Next states
// Ex: DRIVER_CREATED_STATE is the current state, then display 'View All Orders' 
// as the next thing to do
const NEXT_ASSIGN_DRIVER = 'Manage Delivery / Assign Driver'
const NEXT_VIEW_ALL_ORDERS = 'View All Orders'
const NEXT_SIMULATE_PAST_CUTOFF = 'Simulate Past Cutoff'
const NEXT_OUT_FOR_DELIVERY = 'Go To Driver Side / Out For Delivery'
const NEXT_DROP_TOP_DELIVERY = 'Drop First Delivery'
const NEXT_DROP_MIDDLE_DELIVERY = 'Drop Second Delivery'
const NEXT_DROP_BOTTOM_DELIVERY = 'Drop Last Delivery'
const NEXT_COMPLETED = 'Completed'

const top = deliveries.ridge
const middle = deliveries.east
const bottom = deliveries.west

// keeps track of three deliveries' status
var viewed = {
  top: false,
  middle: false,
  bottom: false
}

const initialState = {
  nextState: NEXT_ASSIGN_DRIVER,
  driver: null,
  top,
  middle,
  bottom,
  passedCutOff: false,
  outForDelivery: null,
  cutoffTime: '10:30 AM',
  canGoBack: false,
  menu: menu
}

var statemachine = new machina.Fsm({
  initialize: function(options) {
    this.currentState = initialState
    // History of actions that got us to current state, as well as state names.
    this.history = []
    this.initialized = false
  },
  namespace: 'restaurant',
  initialState: 'init',
  states: {
    init: {
      handleAction: function(event) {
        switch (event) {
          case START_EVENT:
            this.initialized = true
            this.transition('createDriver')
            break
          default:
        }
      }
    },
    createDriver: {
      _onEnter: function() {
        this.history = []
        this.emit(STATE_NAME, this.currentState)
      },
      handleAction: function(event, data) {
        let eventDetails = this.events[event]
        let historyElement = { event, stateName: 'createDriver' }
        switch (event) {
          case DRIVER_CREATED_EVENT:
            this.currentState = eventDetails.forward(this.currentState, data)
            this.history.push(historyElement)
            this.transition('driverAssigned')
            break
          default:
        }
      }
    },
    driverAssigned: {
      _onEnter: function() {
        this.emit(STATE_NAME, this.currentState)
        viewed = { top: false, middle: false, bottom: false }
      },
      handleAction(event) {
        let eventDetails = this.events[event]
        let historyElement = { event, stateName: 'driverAssigned' }
        switch (event) {
          case VIEW_TOP_DELIVERY_EVENT:
            viewed.top = true
            this.currentState = eventDetails.forward(this.currentState)
            this.history.push(historyElement)
            this.transition('topDeliveryViewed')
            break
          case VIEW_MIDDLE_DELIVERY_EVENT:
            viewed.middle = true
            this.currentState = eventDetails.forward(this.currentState)
            this.history.push(historyElement)
            this.transition('middleDeliveryViewed')
            break
          case VIEW_BOTTOM_DELIVERY_EVENT:
            viewed.top = true
            this.currentState = eventDetails.forward(this.currentState)
            this.history.push(historyElement)
            this.transition('bottomDeliveryViewed')
            break
          case VIEW_ALL_DELIVERY_EVENT:
            this.currentState = eventDetails.forward(this.currentState)
            this.history.push(historyElement)
            this.currentState = setNextStateToPastCutoff(this.currentState) 
            this.transition('allDeliveryViewed')
            break
          case BACK_EVENT:
            let details = this.history.pop()
            this.currentState = this.events[details.event].backward(
              this.currentState
            )
            this.transition(details.stateName)
            break
          default:
        }
      }
    },
    topDeliveryViewed: {
      _onEnter: function() {
        this.emit(STATE_NAME, this.currentState)
      },
      handleAction: function(event) {
        let eventDetails = this.events[event]
        let historyElement = { event, stateName: 'topDeliveryViewed' }
        switch (event) {
          case VIEW_MIDDLE_DELIVERY_EVENT:
            viewed.middle = true
            this.currentState = eventDetails.forward(this.currentState)
            this.history.push(historyElement)
            if (viewed.bottom) {
              this.currentState = setNextStateToPastCutoff(this.currentState) 
              this.transition('allDeliveryViewed')
            } else {
              this.transition('middleDeliveryViewed')
            }
            break
          case VIEW_BOTTOM_DELIVERY_EVENT:
            viewed.bottom = true
            this.currentState = eventDetails.forward(this.currentState)
            this.history.push(historyElement)
            if (viewed.middle) {
              this.currentState = setNextStateToPastCutoff(this.currentState) 
              this.transition('allDeliveryViewed')
            } else {
              this.transition('bottomDeliveryViewed')
            }
            break
          case VIEW_ALL_DELIVERY_EVENT:
            this.currentState = eventDetails.forward(this.currentState)
            this.history.push(historyElement)
            this.currentState = setNextStateToPastCutoff(this.currentState) 
            this.transition('allDeliveryViewed')
            break
          case BACK_EVENT:
            viewed.top = false
            let details = this.history.pop()
            this.currentState = this.events[details.event].backward(
              this.currentState
            )
            this.transition(details.stateName)
            break
          default:
        }
      }
    },
    middleDeliveryViewed: {
      _onEnter: function() {
        this.emit(STATE_NAME, this.currentState)
      },
      handleAction: function(event) {
        let eventDetails = this.events[event]
        let historyElement = { event, stateName: 'middleDeliveryViewed' }
        switch (event) {
          case VIEW_TOP_DELIVERY_EVENT:
            this.currentState = eventDetails.forward(this.currentState)
            this.history.push(historyElement)
            if (viewed.bottom) {
              this.currentState = setNextStateToPastCutoff(this.currentState) 
              this.transition('allDeliveryViewed')
            } else {
              this.transition('topDeliveryViewed')
            }
            break
          case VIEW_BOTTOM_DELIVERY_EVENT:
            this.currentState = eventDetails.forward(this.currentState)
            this.history.push(historyElement)
            if (viewed.top) {
              this.currentState = setNextStateToPastCutoff(this.currentState) 
              this.transition('allDeliveryViewed')
            } else {
              this.transition('bottomDeliveryViewed')
            }
            break
          case VIEW_ALL_DELIVERY_EVENT:
            this.currentState = eventDetails.forward(this.currentState)
            this.history.push(historyElement)
            this.currentState = setNextStateToPastCutoff(this.currentState) 
            this.transition('allDeliveryViewed')
            break
          case BACK_EVENT:
            viewed.middle = false
            let details = this.history.pop()
            this.currentState = this.events[details.event].backward(
              this.currentState
            )
            this.transition(details.stateName)
            break
          default:
        }
      }
    },
    bottomDeliveryViewed: {
      _onEnter: function() {
        this.emit(STATE_NAME, this.currentState)
      },
      handleAction: function(event) {
        let eventDetails = this.events[event]
        let historyElement = { event, stateName: 'bottomDeliveryViewed' }
        switch (event) {
          case VIEW_TOP_DELIVERY_EVENT:
            this.currentState = eventDetails.forward(this.currentState)
            this.history.push(historyElement)
            if (viewed.middle) {
              this.currentState = setNextStateToPastCutoff(this.currentState) 
              this.transition('allDeliveryViewed')
            } else {
              this.transition('topDeliveryViewed')
            }
            break
          case VIEW_MIDDLE_DELIVERY_EVENT:
            this.currentState = eventDetails.forward(this.currentState)
            this.history.push(historyElement)
            if (viewed.top) {
              this.currentState = setNextStateToPastCutoff(this.currentState) 
              this.transition('allDeliveryViewed')
            } else {
              this.transition('middleDeliveryViewed')
            }
            break
          case VIEW_ALL_DELIVERY_EVENT:
            this.currentState = eventDetails.forward(this.currentState)
            this.history.push(historyElement)
            this.currentState = setNextStateToPastCutoff(this.currentState) 
            this.transition('allDeliveryViewed')
            break
          case BACK_EVENT:
            viewed.bottom = false
            let details = this.history.pop()
            this.currentState = this.events[details.event].backward(
              this.currentState
            )
            this.transition(details.stateName)
            break
          default:
        }
      }
    },
    allDeliveryViewed: {
      _onEnter: function() {
        this.emit(STATE_NAME, this.currentState)
        viewed.top = true
        viewed.middle = true
        viewed.bottom = true
      },
      handleAction: function(event) {
        let eventDetails = this.events[event]
        let historyElement = { event, stateName: 'allDeliveryViewed' }
        switch (event) {
          case PAST_CUTOFF_EVENT:
            this.currentState = eventDetails.forward(this.currentState)
            this.history.push(historyElement)
            this.transition('passedCutoff')
            break
          case BACK_EVENT:
            this.currentState = this.events[VIEW_ALL_DELIVERY_EVENT].backward(
              this.currentState
            )
            this.transition('driverAssigned')
            break
          default:
        }
      }
    },
    passedCutoff: {
      _onEnter: function() {
        this.emit(STATE_NAME, this.currentState)
      },
      handleAction: function(event) {
        let eventDetails = this.events[event]
        let historyElement = { event, stateName: 'passedCutoff' }
        switch (event) {
          case OUT_FOR_DELIVERY_EVENT:
            this.currentState = eventDetails.forward(this.currentState)
            this.history.push(historyElement)
            this.transition('outForDelivery')
            break
          case BACK_EVENT:
            let details = this.history.pop()
            this.currentState = this.events[details.event].backward(
              this.currentState
            )
            this.transition(details.stateName)
            break
          default:
        }
      }
    },
    outForDelivery: {
      _onEnter: function() {
        this.emit(STATE_NAME, this.currentState)
      },
      handleAction: function(event) {
        let eventDetails = this.events[event]
        let historyElement = { event, stateName: 'outForDelivery' }
        switch (event) {
          case TOP_DELIVERY_DROPPED_EVENT:
            this.currentState = eventDetails.forward(this.currentState)
            this.history.push(historyElement)
            this.transition('topDelivery')
            break
          case LATE_DELIVERY_EVENT:
            this.currentState = eventDetails.forward(this.currentState)
            this.history.push(historyElement)
            this.transition('lateDelivery')
            break
          case BACK_EVENT:
            let details = this.history.pop()
            this.currentState = this.events[details.event].backward(
              this.currentState
            )
            this.transition(details.stateName)
            break
          default:
        }
      }
    },
    lateDelivery: {
      _onEnter: function() {
        this.emit(STATE_NAME, this.currentState)
      },
      handleAction: function(event) {
        let eventDetails = this.events[event]
        let historyElement = { event, stateName: 'lateDelivery' }
        switch (event) {
          case TOP_DELIVERY_DROPPED_EVENT:
            this.currentState = eventDetails.forward(this.currentState)
            this.history.push(historyElement)
            this.transition('topDelivery')
            break
          case BACK_EVENT:
            let details = this.history.pop()
            this.currentState = this.events[details.event].backward(
              this.currentState
            )
            this.transition(details.stateName)
            break
          default:
        }
      }
    },
    topDelivery: {
      _onEnter: function() {
        this.emit(STATE_NAME, this.currentState)
      },
      handleAction: function(event) {
        let eventDetails = this.events[event]
        let historyElement = { event, stateName: 'topDelivery' }
        switch (event) {
          case MIDDLE_DELIVERY_DROPPED_EVENT:
            this.currentState = eventDetails.forward(this.currentState)
            this.history.push(historyElement)
            this.transition('middleDelivery')
            break
          case BACK_EVENT:
            let details = this.history.pop()
            this.currentState = this.events[details.event].backward(
              this.currentState
            )
            this.transition(details.stateName)
            break
          default:
        }
      }
    },
    middleDelivery: {
      _onEnter: function() {
        this.emit(STATE_NAME, this.currentState)
      },
      handleAction: function(event) {
        let eventDetails = this.events[event]
        let historyElement = { event, stateName: 'middleDelivery' }
        switch (event) {
          case BOTTOM_DELIVERY_DROPPED_EVENT:
            this.currentState = eventDetails.forward(this.currentState)
            this.history.push(historyElement)
            this.transition('allDelivery')
            break
          case BACK_EVENT:
            let details = this.history.pop()
            this.currentState = this.events[details.event].backward(
              this.currentState
            )
            this.transition(details.stateName)
            break
          default:
        }
      }
    },
    allDelivery: {
      _onEnter: function() {
        this.emit(STATE_NAME, this.currentState)
      },
      handleAction: function(event) {
        switch (event) {
          case BACK_EVENT:
            let details = this.history.pop()
            this.currentState = this.events[details.event].backward(
              this.currentState
            )
            this.transition(details.stateName)
            break
          default:
        }
      }
    }
  },
  reset: function() {
    this.currentState = initialState
    this.transition('createDriver')
  },

  handleAction: function(event, data) {
    this.handle('handleAction', event, data)
  },

  // Collection of all event transformations, both back and forward
  events: {
    [DRIVER_CREATED_EVENT]: {
      forward: (state, data) => ({ ...state, nextState: NEXT_VIEW_ALL_ORDERS, driver: data, canGoBack: true }),
      backward: state => ({ ...state, nextState: NEXT_ASSIGN_DRIVER, driver: null, canGoBack: false })
    },
    [VIEW_TOP_DELIVERY_EVENT]: {
      forward: state => ({ ...state, top: view(state.top) }),
      backward: state => ({ ...state, top: unview(state.top) })
    },
    [VIEW_MIDDLE_DELIVERY_EVENT]: {
      forward: state => ({ ...state, middle: view(state.middle) }),
      backward: state => ({ ...state, middle: unview(state.middle) })
    },
    [VIEW_BOTTOM_DELIVERY_EVENT]: {
      forward: state => ({ ...state, bottom: view(state.bottom) }),
      backward: state => ({ ...state, bottom: unview(state.bottom) })
    },
    [VIEW_ALL_DELIVERY_EVENT]: {
      forward: state => ({
        ...state,
        nextState: NEXT_SIMULATE_PAST_CUTOFF,
        top: view(state.top),
        middle: view(state.middle),
        bottom: view(state.bottom)
      }),
      backward: state => ({
        ...state,
        nextState: NEXT_VIEW_ALL_ORDERS,
        top: unview(state.top),
        middle: unview(state.middle),
        bottom: unview(state.bottom)
      })
    },
    [PAST_CUTOFF_EVENT]: {
      forward: state => ({ ...state, nextState: NEXT_OUT_FOR_DELIVERY, passedCutOff: true }),
      backward: state => ({ ...state, nextState: NEXT_SIMULATE_PAST_CUTOFF, passedCutOff: false })
    },
    [OUT_FOR_DELIVERY_EVENT]: {
      forward: state => ({ ...state, nextState: NEXT_DROP_TOP_DELIVERY, outForDelivery: new Date() }),
      backward: state => ({ ...state, nextState: NEXT_OUT_FOR_DELIVERY, outForDelivery: null })
    },
    [LATE_DELIVERY_EVENT]: {
      forward: state => ({ ...state, top: late(state.top) }),
      backward: state => ({ ...state, top: unlate(state.top) })
    },
    [TOP_DELIVERY_DROPPED_EVENT]: {
      forward: state => ({ ...state, nextState: NEXT_DROP_MIDDLE_DELIVERY, top: deliver(state.top) }),
      backward: state => ({ ...state, nextState: NEXT_DROP_TOP_DELIVERY, top: undeliver(state.top) })
    },
    [MIDDLE_DELIVERY_DROPPED_EVENT]: {
      forward: state => ({ ...state, nextState: NEXT_DROP_BOTTOM_DELIVERY, middle: deliver(state.middle) }),
      backward: state => ({ ...state, nextState: NEXT_DROP_MIDDLE_DELIVERY, middle: undeliver(state.middle) })
    },
    [BOTTOM_DELIVERY_DROPPED_EVENT]: {
      forward: state => ({ ...state, nextState: NEXT_COMPLETED, bottom: deliver(state.bottom) }),
      backward: state => ({ ...state, nextState: NEXT_DROP_BOTTOM_DELIVERY, bottom: undeliver(state.bottom) })
    }
  }
})

const setNextStateToPastCutoff = state => ({
  ...state, 
  nextState: NEXT_SIMULATE_PAST_CUTOFF
})

const view = row => ({
  ...row,
  viewedCount: row.unviewedCount !== 0 ? row.unviewedCount : row.viewedCount,
  unviewedCount: 0
})

const unview = row => ({
  ...row,
  viewedCount: 0,
  unviewedCount: row.viewedCount !== 0 ? row.viewedCount : row.unviewedCount
})

const deliver = row => ({
  ...row,
  delivered: new Date()
})

const undeliver = row => ({
  ...row,
  delivered: null
})

const late = row => ({
  ...row,
  late: true
})
const unlate = row => ({
  ...row,
  late: false
})

// Example of using handle action from the statemachine
// statemachine.handleAction(START_EVENT)

// statemachine.handleAction(DRIVER_CREATED_EVENT, {name: 'carl', number: '1'})
// statemachine.handleAction(BACK_EVENT)
// statemachine.handleAction(DRIVER_CREATED_EVENT, {name: 'carl', number: '2'})

// statemachine.handleAction(VIEW_BOTTOM_DELIVERY_EVENT)
// statemachine.handleAction(BACK_EVENT)
// statemachine.handleAction(VIEW_BOTTOM_DELIVERY_EVENT)

// statemachine.handleAction(VIEW_TOP_DELIVERY_EVENT)
// statemachine.handleAction(BACK_EVENT)
// statemachine.handleAction(VIEW_TOP_DELIVERY_EVENT)

// statemachine.handleAction(PAST_CUTOFF_EVENT)
// statemachine.handleAction(BACK_EVENT)
// statemachine.handleAction(PAST_CUTOFF_EVENT)

// statemachine.handleAction(OUT_FOR_DELIVERY_EVENT)
// statemachine.handleAction(BACK_EVENT)
// statemachine.handleAction(OUT_FOR_DELIVERY_EVENT)

// statemachine.handleAction(LATE_DELIVERY_EVENT)
// statemachine.handleAction(BACK_EVENT)
// statemachine.handleAction(LATE_DELIVERY_EVENT)

// statemachine.handleAction(TOP_DELIVERY_DROPPED_EVENT)
// statemachine.handleAction(BACK_EVENT)
// statemachine.handleAction(TOP_DELIVERY_DROPPED_EVENT)

// statemachine.handleAction(BOTTOM_DELIVERY_DROPPED_EVENT)
// statemachine.handleAction(BACK_EVENT)
// statemachine.handleAction(BOTTOM_DELIVERY_DROPPED_EVENT)


// statemachine.reset()
// statemachine.handleAction(DRIVER_CREATED_EVENT, {name: 'carl', number: '3'})

export {
  statemachine,
  DRIVER_CREATED_EVENT,
  VIEW_BOTTOM_DELIVERY_EVENT,
  VIEW_MIDDLE_DELIVERY_EVENT,
  VIEW_TOP_DELIVERY_EVENT,
  VIEW_ALL_DELIVERY_EVENT,
  PAST_CUTOFF_EVENT,
  OUT_FOR_DELIVERY_EVENT,
  BOTTOM_DELIVERY_DROPPED_EVENT,
  LATE_DELIVERY_EVENT,
  TOP_DELIVERY_DROPPED_EVENT,
  MIDDLE_DELIVERY_DROPPED_EVENT,
  STATE_NAME,
  START_EVENT,
  BACK_EVENT
}
