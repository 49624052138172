import React from 'react'
import { format } from 'date-fns'
import { Button } from '@foodsby/nutrient'

export const DeliveryDetailHeaders = () => (
  <div className="row">
    <div className="col-xs-4">
      <strong>Location with orders</strong>
    </div>
    <div className="col-xs-2">
      <strong>Not Viewed</strong>
    </div>
    <div className="col-xs-2">
      <strong>Viewed</strong>
    </div>
    <div className="col-xs-2">
      <strong>Dropoff</strong>
    </div>
    <div className="col-xs-2">
      <strong>Delivered Time</strong>
    </div>
  </div>
)

const DeliveryDetail = ({ data, passedCutOff, view, deliver }) => (
  <div className="row middle-xs">
    <div className="col-xs-4">{data.delivery.location}</div>
    <div className="col-xs-2">
      <button className="viewButton" onClick={view}>
        {data.unviewedCount}
      </button>
    </div>
    <div className="col-xs-2">
      <button className="viewButton">{data.viewedCount}</button>
    </div>
    <div className="col-xs-2">{data.delivery.time}</div>
    <div className="col-xs-2">
      {data.delivered ? (
        <div>{format(data.delivered, 'h:mm A')}</div>
      ) : (
        <Button onClick={deliver} disabled={!passedCutOff}>
          AT DROPOFF
        </Button>
      )}
    </div>
    <style jsx>{`
      .viewButton {
        width: 85px;
        height: 35px;
        font-size: 18px;
      }
    `}</style>
  </div>
)

export default DeliveryDetail
