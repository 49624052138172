import top1 from '../images/top-instructions/1.png'
import top2 from '../images/top-instructions/2.png'
import top3 from '../images/top-instructions/3.jpeg'
import top4 from '../images/top-instructions/4.jpeg'

import bottom1 from '../images/bottom-instructions/1.png'
import bottom2 from '../images/bottom-instructions/2.png'
import bottom3 from '../images/bottom-instructions/3.jpg'
import bottom4 from '../images/bottom-instructions/4.jpg'
import bottom5 from '../images/bottom-instructions/5.jpg'

const deliveries = {
  ridge: {
    totalCount: 7,
    unviewedCount: 7,
    viewedCount: 0,
    delivered: null,
    late: false,
    delivery: {
      location: 'Broadway Ridge',
      street: '3001 Broadway St. NE',
      cityStateZip: 'Minneapolis, MN 55413',
      instructions:
        'Drop off all meals to the Foodsby kiosk located on the lower level lounge.',
      time: '11:50 AM',
      visualInstructions: [
        {
          text: 'Building is at the intersection of Hoover & Broadway',
          image: top1
        },
        {
          text: 'Park right in front, Enter the elevator down to the lower level.',
          image: top2
        },
        {
          text: 'Leave the elevator and enter the common area.',
          image: top3
        },
        {
          text: 'The Foodsby kiosks are located on the left by the the barn door.',
          image: top4
        }
      ]
    },
    orders: [
      {
        orderId: 1234,
        dropId: 2345,
        name: 'Larry Leonard',
        phone: '(555) 555-5555',
        delivery: {
          locationName: 'Broadway Ridge',
          street: '3001 Broadway St. NE',
          cityStateZip: 'Minneapolis, MN 55413',
          instructions:
            'Drop off all meals to the Foodsby kiosk located on the lower level lounge.',
          time: '11:50 AM'
        },
        refund: null,
        restaurantName: 'The Golden Spoon',
        orderItem: {
          price: 8.5,
          refunded: 0.0,
          name: 'The Porker',
          modifiers: ['BBQ Sauce to the max']
        }
      },
      {
        orderId: 1235,
        dropId: 2345,
        name: 'Larry Leonard',
        phone: '(555) 555-5555',
        delivery: {
          locationName: 'Broadway Ridge',
          street: '3001 Broadway St. NE',
          cityStateZip: 'Minneapolis, MN 55413',
          instructions:
            'Drop off all meals to the Foodsby kiosk located on the lower level lounge.',
          time: '11:50 AM'
        },
        refund: {
          amount: 10.5,
          reason:
            'Received the wrong order today. Instead of turkey got ham - instead of wheat got white. And no side salad that I paid extra for.'
        },
        restaurantName: 'The Golden Spoon',
        orderItem: {
          price: 8.5,
          refunded: 10.5,
          name: 'The Porker',
          modifiers: ['BBQ Sauce to the max']
        }
      }
    ]
  },
  west: {
    totalCount: 4,
    unviewedCount: 4,
    viewedCount: 0,
    delivered: null,
    late: false,
    delivery: {
      location: 'Broadway West',
      street: '1300 Godward St. NE',
      cityStateZip: 'Minneapolis, MN 55413',
      instructions:
        'MAKE SURE ALL MEALS ARE FOR BROADWAY WEST!! All meals to Foodsby kiosk located in the Avenue C market. Enter the main lobby, take a left down the main hallway. The Avenue C market will be on the left. The kiosk is in the back of the micro-market.',
      time: '12:10 PM',
      visualInstructions: [
        {
          text: 'WEST has BLUE columns. Building is overlooking hwy 36 and west of of the Ramada',
          image: bottom1
        },
        {
          text: 'WEST = BLUE pillars. Enter in main doors, take a left at the main hallway, and the micro-market will be on your left.',
          image: bottom2
        },
        {
          text: 'Take a left down the main hallway.',
          image: bottom3
        },
        {
          text: 'Enter the micro-market',
          image: bottom4
        },
        {
          text: 'The cart will be located near the freezer.',
          image: bottom5
        }
      ]
    },
    orders: [
      {
        orderId: 1236,
        dropId: 2346,
        name: 'Larry Leonard',
        phone: '(555) 555-5555',
        delivery: {
          locationName: 'Broadway West',
          street: '1300 Godward St. NE',
          cityStateZip: 'Minneapolis, MN 55413',
          instructions:
            'MAKE SURE ALL MEALS ARE FOR BROADWAY WEST!! All meals to Foodsby kiosk located in the Avenue C market. Enter the main lobby, take a left down the main hallway. The Avenue C market will be on the left. The kiosk is in the back of the micro-market.',
          time: '12:10 PM'
        },
        refund: null,
        restaurantName: 'The Golden Spoon',
        orderItem: {
          price: 8.5,
          refunded: 0.0,
          name: 'The Porker',
          modifiers: ['BBQ Sauce to the max']
        }
      }
    ]
  },
  east: {
    totalCount: 12,
    unviewedCount: 12,
    viewedCount: 0,
    delivered: null,
    late: false,
    delivery: {
      location: 'Broadway East',
      street: '3433 Broadway St NE',
      cityStateZip: 'Minneapolis, MN 55413',
      instructions:
        'Enter the main lobby, take a left down the main hallway. The Avenue C market will be on the left. The kiosk is in the back of the micro-market.',
      time: '12:00 PM',
      visualInstructions: [
        {
          text: 'WEST has BLUE columns. Building is overlooking hwy 36 and west of of the Ramada',
          image: bottom1
        },
        {
          text: 'WEST = BLUE pillars. Enter in main doors, take a left at the main hallway, and the micro-market will be on your left.',
          image: bottom2
        },
        {
          text: 'Take a left down the main hallway.',
          image: bottom3
        },
        {
          text: 'Enter the micro-market',
          image: bottom4
        },
        {
          text: 'The cart will be located near the freezer.',
          image: bottom5
        }
      ]
    },
    orders: [
      {
        orderId: 1237,
        dropId: 2347,
        name: 'Larry Leonard',
        phone: '(555) 555-5555',
        delivery: {
          locationName: 'Broadway East',
          street: '3433 Broadway St NE',
          cityStateZip: 'Minneapolis, MN 55413',
          instructions:
            'Enter the main lobby, take a left down the main hallway. The Avenue C market will be on the left. The kiosk is in the back of the micro-market.',
          time: '12:00 PM'
        },
        refund: null,
        restaurantName: 'The Golden Spoon',
        orderItem: {
          price: 8.5,
          refunded: 0.0,
          name: 'The Porker',
          modifiers: ['BBQ Sauce to the max']
        }
      }
    ]
  },
  stinson: {
    unviewedCount: 1,
    viewedCount: 0,
    delivered: null,
    late: false,
    delivery: {
      location: '400 Stinson',
      street: '400 NE Stinson Blvd',
      cityStateZip: 'Minneapolis, MN 55413',
      instructions:
        'All meals to Foodsby kiosk located in the Avenue C market. Enter the main lobby, take a left down the main hallway. The Avenue C market will be on the left. The kiosk is in the back of the micro-market.',
      time: '12:00 PM',
      visualInstructions: [
        {
          text: 'WEST has BLUE columns. Building is overlooking hwy 36 and west of of the Ramada',
          image: bottom1
        },
        {
          text: 'WEST = BLUE pillars. Enter in main doors, take a left at the main hallway, and the micro-market will be on your left.',
          image: bottom2
        },
        {
          text: 'Take a left down the main hallway.',
          image: bottom3
        },
        {
          text: 'Enter the micro-market',
          image: bottom4
        },
        {
          text: 'The cart will be located near the freezer.',
          image: bottom5
        }
      ]
    },
    orders: [
      {
        orderId: 1238,
        dropId: 2348,
        name: 'Larry Leonard',
        phone: '(555) 555-5555',
        delivery: {
          locationName: '400 Stinson',
          street: '400 NE Stinson Blvd',
          cityStateZip: 'Minneapolis, MN 55413',
          instructions:
            'All meals to Foodsby kiosk located in the Avenue C market. Enter the main lobby, take a left down the main hallway. The Avenue C market will be on the left. The kiosk is in the back of the micro-market.',
          time: '12:00 PM'
        },
        refund: null,
        restaurantName: 'The Golden Spoon',
        orderItem: {
          price: 8.5,
          refunded: 0.0,
          name: 'The Porker',
          modifiers: ['BBQ Sauce to the max']
        }
      }
    ]
  }
}

export default deliveries
