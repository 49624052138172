import React from 'react'
import { Card } from '@foodsby/nutrient'

const LocationInfo = () => (
  <div className="wrapper">
    <Card>
      <h3>Location Information</h3>
      <div className="col-xs-6 left-col">
        <table>
          <tbody>
            <tr>
              <td>Address</td>
              <td>
                3101 East Hennepin Ave,
                <br /> Minneapolis, MN 55413
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="col-xs-6 right-col">
        <table>
          <tbody>
            <tr>
              <td>Status:</td>
              <td>Active</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Card>
    <style jsx global>{`
      .wrapper {
        width: 1000px;
        text-align: center;
        & table,
        & td,
        & th {
          border: none;
        }
      }

      .left-col {
        border-right: 1px solid var(--nu-light-grey);
      }
    `}</style>
  </div>
)

export default LocationInfo
