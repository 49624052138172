import React, { PureComponent, Fragment } from 'react'
import RestaurantSideNav from '../../components/restaurant/RestaurantSideNav'

// Tabs package
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'

import Calendar from '../../components/restaurant/Calendar'
import Drivers from '../../components/restaurant/Drivers'
import DeliveryRules from '../../components/restaurant/DeliveryRules'

class Manage extends PureComponent {
  render() {
    return (
      <Fragment>
        <div className="wrapper">
          <RestaurantSideNav />
          <div className="content">
            <h2>Manage</h2>
            <Tabs selectedTabClassName="selected-tab" className="tabs">
              <TabList className="tab-list">
                <Tab>Calendar View</Tab>
                <Tab>Drivers</Tab>
                <Tab>Delivery Rules</Tab>
              </TabList>

              <TabPanel>
                <Calendar />
              </TabPanel>
              <TabPanel>
                <Drivers />
              </TabPanel>
              <TabPanel>
                <DeliveryRules />
              </TabPanel>
            </Tabs>
          </div>
        </div>
        <style jsx global>{`
          @import '@foodsby/nutrient/src/css/vars.css';
          .wrapper {
            display: flex;
            flex-direction: row;
            height: 100%;
          }

          .content {
            margin-left: 32px;
          }

          .tabs {
            background-color: white;
            padding: 24px;
            box-shadow: 15px 15px 15px 15px rgba(20, 20, 20, 0.05);
          }

          .tab-list {
          }

          .selected-tab {
            color: var(--nu-secondary);
            border-bottom: 2px solid var(--nu-secondary);
          }
        `}</style>
      </Fragment>
    )
  }
}

export default Manage
