import React, { PureComponent } from 'react'
import { Card, Button } from '@foodsby/nutrient'
// Date picker package
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

class StatsHeader extends PureComponent {
  constructor(props, context) {
    super(props, context)
    let today = new Date()
    let weekAgo = new Date()
    weekAgo.setDate(today.getDate() - 6)

    this.state = {
      startDate: weekAgo,
      endDate: today
    }
  }

  handleChange = (date, type) => {
    this.setState({ [type]: date })
  }

  handleSubmit = () => {
    this.props.submit(this.state.startDate, this.state.endDate)
  }

  render() {
    const { startDate, endDate } = this.state
    const {
      totalSales,
      totalRefunded,
      totalDeliveries,
      totalRefunds,
      totalMeals,
      bestDay
    } = this.props

    return (
      <div className="wrapper">
        <Card>
          <div className="search">
            <h3>From:</h3>
            <DatePicker
              selected={startDate}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              className="date-picker"
              onChange={date => this.handleChange(date, 'startDate')}
            />
            <h3>To:</h3>
            <DatePicker
              selected={endDate}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              className="date-picker"
              onChange={date => this.handleChange(date, 'endDate')}
            />
            <Button onClick={this.handleSubmit}>RUN</Button>
          </div>
          <div className="col-xs-6 left-col">
            <table>
              <tbody>
                <tr>
                  <td>Total Sales:</td>
                  <td>${totalSales.toFixed(2)}</td>
                </tr>
                <tr>
                  <td>Total Refunded:</td>
                  <td>${totalRefunded.toFixed(2)}</td>
                </tr>
                <tr>
                  <td>Best Delivery:</td>
                  <td>{totalSales !== 0 ? `${endDate.toLocaleDateString()}: $${bestDay.toFixed(2)}` : '$0.00'}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-xs-6 right-col">
            <table>
              <tbody>
                <tr>
                  <td>Total Deliveries:</td>
                  <td>{totalDeliveries}</td>
                </tr>
                <tr>
                  <td>Total Refunds:</td>
                  <td>{totalRefunds}</td>
                </tr>
                <tr>
                  <td>Total Meals</td>
                  <td>{totalMeals}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Card>
        <style jsx global>{`
          .wrapper {
            width: 1000px;
            text-align: center;
            & table,
            & td,
            & th {
              border: none;
            }
          }

          .left-col {
            border-right: 1px solid var(--nu-light-grey);
          }

          .search {
            display: flex;
            flex-direction: row;
            justify-content: center;
            border-bottom: 1px solid var(--nu-light-grey);
            margin-bottom: 12px;
          }

          .search > * {
            margin-left: 20px;
          }

          .date-picker {
            border: none;
            border-bottom: 1px solid black;
            height: 30px;
            font-size: 20px;
          }
        `}</style>
      </div>
    )
  }
}

export default StatsHeader
