import React, { PureComponent } from 'react'
import Receipt from '../common/Receipt'
import NewWindow from 'react-new-window'

class ReceiptWindow extends PureComponent {
  render() {
    const {
      onCancel,
      allOrdersViewed,
      top,
      middle,
      bottom,
      dropSymbolTop,
      dropSymbolMiddle,
      dropSymbolBottom,
      receiptProps
    } = this.props
    return (
      <NewWindow onUnload={onCancel} name="Receipt" title="Receipt">
        {allOrdersViewed ? (
          <div className="wrapper">
            <Receipt
              data={top}
              numOfOrders={top.totalCount}
              DropSymbol={dropSymbolTop}
            />
            <Receipt
              data={middle}
              numOfOrders={middle.totalCount}
              DropSymbol={dropSymbolMiddle}
            />
            <Receipt
              data={bottom}
              numOfOrders={bottom.totalCount}
              DropSymbol={dropSymbolBottom}
            />
          </div>
        ) : (
          <div className="wrapper">
            <Receipt
              data={receiptProps.data}
              numOfOrders={receiptProps.numOfOrders}
              DropSymbol={receiptProps.dropSymbol}
            />
          </div>
        )}
        <style jsx>{`
          .wrapper {
            padding: 48px;
          }
        `}</style>
      </NewWindow>
    )
  }
}

export default ReceiptWindow
