import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { restaurantRoutes } from '../../routes'

class CalendarDay extends PureComponent {
  render() {
    const { day, orders, disabled } = this.props
    return (
      <div className="day-box">
        <div className={disabled ? 'day-no disabled' : 'day-no'}>{day}</div>
        {orders &&
          orders.map((order, key) => (
            <Link
              to={`${restaurantRoutes.PREV_ORDER_DETAILS}/05-${day}-2019`}
              key={key}
            >
              <div className="order-box">
                <div>
                  Cutoff: {order.cutoff}{' '}
                  <span className="right-column">Orders:</span>
                </div>
                <div>
                  Drops: {order.drops}{' '}
                  <span className="right-column">Sales:</span>
                </div>
              </div>
            </Link>
          ))}
        <style jsx>{`
          .day-box {
            height: 100px;
            width: 120px;
            position: relative;
          }

          .day-no {
            float: right;
            font-size: 18px;
            padding: 12px;
          }

          .disabled {
            color: var(--nu-light-grey);
          }

          .order-box {
            position: absolute;
            background-color: rgb(77, 134, 169);
            color: white;
            padding: 1px;
            border-radius: 4px;
            border: 1px solid var(--nu-secondary);
            font-size: 13px;
            width: 100%;
            height: 37px;
            bottom: 8px;

            &:hover {
              cursor: pointer;
            }
          }

          .order-box .right-column {
            float: right;
          }
        `}</style>
      </div>
    )
  }
}

export default CalendarDay
