import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import PrivateRoute from './layouts/PrivateRoute'
import Main from './layouts/Main'
import { mainRoutes, restaurantRoutes } from './routes'
/* Context */
import AppProvider from './context/AppContext'
/* Pages */
import Login from './pages/Login'
import Today from './pages/restaurant_pages/Today'
import Manage from './pages/restaurant_pages/Manage'
import StoreInfo from './pages/restaurant_pages/StoreInfo'
import PreviousOrderDetails from './pages/restaurant_pages/PreviousOrderDetails'
import Menu from './pages/restaurant_pages/Menu'
import Documents from './pages/restaurant_pages/Documents'
import Stats from './pages/restaurant_pages/Stats'
import Drivers from './pages/Drivers'

const NoMatch = ({ location }) => (
  <div>
    <h1>
      404 URL not found: <code>{location.pathname}</code>
    </h1>
  </div>
)

const App = () => (
  <BrowserRouter>
    <Route
      render={({ location, history }) => (
        <AppProvider>
          <Main>
            <Switch>
              {/* Restaurant Routes */}
              <PrivateRoute
                exact
                path={restaurantRoutes.TODAY}
                component={props => <Today {...props} />}
              />
              <PrivateRoute
                exact
                path={restaurantRoutes.MANAGE}
                component={props => <Manage {...props} />}
              />
              <PrivateRoute
                path={restaurantRoutes.STORE_INFO}
                component={props => <StoreInfo {...props} />}
              />
              <PrivateRoute
                path={restaurantRoutes.MENU}
                component={props => <Menu {...props} />}
              />
              <PrivateRoute
                path={restaurantRoutes.DOCUMENTS}
                component={props => <Documents {...props} />}
              />
              <PrivateRoute
                path={restaurantRoutes.STATS}
                component={props => <Stats {...props} />}
              />
              <PrivateRoute
                path={`${restaurantRoutes.PREV_ORDER_DETAILS}/:date`}
                component={props => <PreviousOrderDetails {...props} />}
              />
              {/* Driver Routes */}
              <PrivateRoute
                path={mainRoutes.DRIVER}
                component={props => <Drivers {...props} />}
              />
              {/* Login Routes */}
              <Route
                path={mainRoutes.HOME}
                component={props => <Login {...props} />}
              />
              {/* No Match */}
              <Route component={NoMatch} />
            </Switch>
          </Main>
          <style jsx global>{`
            @import '@foodsby/nutrient/dist/nutrient.css';

            [class^='icon-'],
            [class*=' icon-'] {
              font-size: 80%;
            }

            #root {
              display: flex;
              min-height: 100%;

              @media screen and (max-width: 767px) {
                flex-direction: column;
              }
            }

            .autocomplete-wrapper {
              & .autocomplete-dropdown {
                & button {
                  min-height: 38px;
                  line-height: 18px;
                  &[data-selected='true'] {
                    position: relative;
                  }
                }
              }
            }
            body {
              background-color: white;
            }
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
              font-weight: 400;
            }
          `}</style>
        </AppProvider>
      )}
    />
  </BrowserRouter>
)

export default App
