import React, { PureComponent } from 'react'
import { Button, Card, Modal } from '@foodsby/nutrient'
import { format } from 'date-fns'
import Questions from './Questions'
import MapModal from './MapModal'
import OrderDetail from './OrderDetail'

class DeliveryDetailScreen extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      modalTitle: null,
      modalText: null,
      directionsExpanded: false,
      ordersExpanded: false,
      mapVisible: false
    }
  }

  confirmDrop = () => {
    this.props.confirmDrop()
    this.setState({
      modalTitle: this.props.lastDrop ? "You're all done!" : 'Good Work!',
      modalText: `${
        this.props.lastDrop ? 'Last drop' : 'Drop'
      } complete @${format(this.props.row.delivered, 'h:mm A')}`
    })
  }

  resetDisplay = () => {
    this.setState({
      modalTitle: null,
      modalText: null,
      directionsExpanded: false
    })
  }

  onNext = () => {
    this.resetDisplay()
    this.props.next()
  }

  toggleDirections = () => {
    this.setState({ directionsExpanded: !this.state.directionsExpanded })
  }

  toggleOrders = () => {
    this.setState({ ordersExpanded: !this.state.ordersExpanded })
  }

  toggleMap = () => {
    this.setState({ mapVisible: !this.state.mapVisible })
  }

  render() {
    const { row, back, outForDelivery, lastDrop } = this.props
    const { mapVisible } = this.state
    return (
      <div className="wrapper">
        <Questions />
        <Card>
          <div className="building-info" onClick={this.toggleMap}>
            <div className="icon">
              <i className="icon-location" />
            </div>
            <div>
              <p>
                <strong>{row.delivery.location}</strong>
              </p>
              <div>
                {row.delivery.street}
                {row.delivery.cityStateZip}
              </div>
            </div>
          </div>
        </Card>
        {mapVisible && <MapModal toggleMap={this.toggleMap} />}
        <Card onClick={this.toggleDirections} className="cursor-pointer">
          <i className="icon-info-circle" /> Click for directions and images
          {this.state.directionsExpanded && (
            <div>
              <br />
              <h5>{row.delivery.instructions}</h5>
            </div>
          )}
          {this.state.directionsExpanded &&
            row.delivery.visualInstructions.map((item, key) => (
              <div key={key}>
                <h5>{item.text}</h5>
                <img
                  alt="meaningful"
                  src={item.image}
                  style={{ width: '80%' }}
                />
              </div>
            ))}
        </Card>
        <Card onClick={this.toggleOrders} className="cursor-pointer">
          <i className="icon-cart" /> Click to see orders
          {this.state.ordersExpanded && (
            <div>
              <OrderDetail order={row.orders[0]} orderNo={row.orders[0].orderId} />
              <OrderDetail order={row.orders[0]} orderNo={row.orders[0].orderId + 1} />
              <div className="center-xs">...More orders would appear here...</div>
            </div>
          )}
        </Card>
        <br />
        {row.delivered ? (
          <h3 className="white-text">
            Dropped off at - {format(row.delivered, 'h:mm A')}
          </h3>
        ) : (
          <Button onClick={this.confirmDrop} disabled={!outForDelivery}>
            CONFIRM DROP
          </Button>
        )}
        {this.state.modalTitle && (
          <Modal showCancel={false}>
            <h2>{this.state.modalTitle}</h2>
            <h4>{this.state.modalText}</h4>
            {lastDrop ? (
              <Button onClick={back}>GOT IT!</Button>
            ) : (
              <div>
                <Button onClick={back}>GO BACK</Button>
                <Button onClick={this.onNext}>NEXT DROP</Button>
              </div>
            )}
          </Modal>
        )}

        <Button onClick={back}>GO BACK</Button>
        <style jsx>{`
          .wrapper {
            padding: 24px;
          }

          .building-info {
            display: flex;
            flex-direction: row;
            &:hover {
              cursor: pointer;
            }
          }

          .icon {
            margin: 24px 24px 0px;
          }

          .icon-location {
            font-size: 32px;
            color: #2476c9;
          }
        `}</style>
      </div>
    )
  }
}

export default DeliveryDetailScreen
