import { JwtHost } from '@foodsby/webapp-jwt'
const tokenName = 'fb-token'

let host
export const initTokens = () => {
  host = new JwtHost([window.location.origin])
  window.addEventListener('storage', e => {
    if (e.key === tokenName && e.oldValue !== e.newValue) {
      window.location.reload()
    }
  })
}

export const storeTokens = tokens => {
  host.storeTokens(tokens)
}

export const getAccessToken = () => host.getAccessToken()

export const getRefreshToken = () => host.getRefreshToken()

export const getDecodedAccessToken = () => host.getDecodedAccessToken()

export const isAccessTokenExpired = () => host.isAccessTokenExpired()

export const removeTokens = () => {
  host.removeTokens()
}
