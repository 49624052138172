import React from 'react'
import { Action } from '@foodsby/nutrient'

const DriverTextMessage = ({ clickLink }) => (
  <div className="wrapper center-xs">
    <div className="header">
      <h5>Foodsby Support</h5>
    </div>
    <div className="content">
      <div className="text-message">
        <p>
          Please follow the link to view your delivery.{' '}
          <Action onClick={clickLink}>
            https://foodsby.com/s/d/43241
          </Action>{' '}
          call Foodsby 612 444 3395
        </p>
      </div>
    </div>
    <style jsx>{`
      @import '@foodsby/nutrient/src/css/vars.css';

      .wrapper {
        background-color: white;
        border: 5px;
        height: 100%;
      }

      .header {
        box-shadow: 15px 15px 30px 16px rgba(20, 20, 20, 0.05);
        border-bottom: 1px solid rgba(20, 20, 20, 0.05);
        background-color: var(--nu-grey-blue);
        height: 70px;
      }

      .header > h5 {
        padding-top: 18px;
      }

      .content {
        background-color: white;
        padding: 24px 12px;

        & > .text-message {
          text-align: left;
          height: 90px;
          padding: 14px;
          width: 330px;
          background-color: var(--nu-grey-blue);
          border-radius: 15px;
        }
      }
    `}</style>
  </div>
)

export default DriverTextMessage
