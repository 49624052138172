import React, { Fragment } from 'react'
import { Card } from '@foodsby/nutrient'

import map_img from '../../images/google_maps.png'
import directions_img from '../../images/google_maps_directions.png'

const DirectionsTab = () => (
  <Fragment>
    <div className="wrapper">
      <Card>
        <h2>Directions</h2>
        <img className="map-img" alt="map" src={map_img} />
        <img className="directions-img" alt="directions" src={directions_img} />
      </Card>

      <style jsx global>{`
        .wrapper {
          text-align: center;
          box-shadow: 10px 10px 20px 8px rgba(20, 20, 20, 0.05);
          margin-left: 24px;
          width: 900px;
        }

        .map-img {
          width: 100%;
          margin-bottom: 12px;
        }

        .directions-img {
          width: 60%;
        }
      `}</style>
    </div>
  </Fragment>
)

export default DirectionsTab
