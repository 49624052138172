import React from 'react'
import { format } from 'date-fns'
import { Button } from '@foodsby/nutrient'

const RestaurantActions = ({
  passedCutOff,
  cutoffTime,
  viewAll,
  handleOutForDelivery,
  outForDelivery
}) => (
  <div className="row">
    <div className="col-xs-6 center-xs">
      <Button onClick={viewAll}>ALL MEALS</Button>
    </div>
    <div className="col-xs-6 center-xs">
      {outForDelivery ? (
        <h6>Left for delivery at {format(outForDelivery, 'h:mm A')}</h6>
      ) : (
        <Button onClick={handleOutForDelivery} disabled={!passedCutOff}>
          OUT FOR DELIVERY
        </Button>
      )}
      <div>Cutoff: {cutoffTime}</div>
    </div>
  </div>
)

export default RestaurantActions
