import React from 'react'
import { Form, FormField, Button } from '@foodsby/nutrient'

const AddDriver = ({ submit }) => (
  <Form onSubmit={submit}>
    <div className="title">Driver Info</div>
    <FormField type="hidden" name="EVENT_NAME" value="DRIVER_CREATED" />
    <FormField label="Name" type="text" name="name" />
    <FormField label="Phone Number" type="text" name="number" />
    <Button type="submit">Create Driver</Button>
  </Form>
)

export default AddDriver
