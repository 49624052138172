import React from 'react'
import { Card, Collapsible } from '@foodsby/nutrient'

import OrderDetailCollapsible from './OrderDetailCollapsible'

const ONE_SALE = 8.5 // 8.5 is a constant price that is given based on the data in deliveries

const DetailByBuilding = ({ building }) => {
  let totalSalesByBuilding = ONE_SALE * building.totalCount 
  let totalRefundsByBuilding = building.orders.reduce((sum, order) => sum + order.orderItem.refunded, 0)

  let allOrders = []
  // Add one refunded order if building.orders has one
  if (building.orders.length > 1) allOrders.push({ ...building.orders[1] })
  // Add the rest of the orders based on totalCount of that building
  for (let i = 0; i < building.totalCount - 1; i++) {
    allOrders.push({ ...building.orders[0] }) // Index of 0 or 1 of orders will be added to allOrders
  }
  

  const header = (
    <span>
      {building.delivery.location} | {building.delivery.time} | Total: $
      {totalSalesByBuilding.toFixed(2)} | Total Refunded: -$
      {totalRefundsByBuilding.toFixed(2)} | Total Meals:&nbsp;
      {building.totalCount}
    </span>
  )

  return (
    <div className="wrapper">
      <Card>
        <Collapsible trigger={header}>
          <div className="inside-collapsible">
            {allOrders.map((order, key) => {
              return <OrderDetailCollapsible order={order} key={key} orderKey={key} />
            })}
          </div>
        </Collapsible>
      </Card>
      <style jsx>{`
        .wrapper {
          margin-bottom: 15px;
          text-align: left;
          box-shadow: 10px 10px 20px 8px rgba(20, 20, 20, 0.05);
        }
      `}</style>
    </div>
  )
}

export default DetailByBuilding
