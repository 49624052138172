import React from 'react'
import { Modal } from '@foodsby/nutrient'
import img from '../../images/google_maps_mobile.png'

const MapModal = ({ toggleMap }) => (
  <Modal onCancel={toggleMap}>
    <div className="mobile-screen">
      <img alt="Map" src={img} />
    </div>
    <style jsx>{`
      img {
        height: 100%;
        width: 100%;
      }
    `}</style>
  </Modal>
)

export default MapModal
