import React from 'react'
import { Action } from '@foodsby/nutrient'

const Drivers = () => (
  <table>
    <thead>
      <tr>
        <th>Name</th>
        <th>Number</th>
        <th>Phone Type</th>
        <th />
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Marc L</td>
        <td>(612) 342 46 23</td>
        <td>Smart</td>
        <td>
          <Action>DELETE</Action>
        </td>
      </tr>
      <tr>
        <td>Nancy</td>
        <td>(318) 412 34 67</td>
        <td>Smart</td>
        <td>
          <Action>DELETE</Action>
        </td>
      </tr>
      <tr>
        <td>Kevin</td>
        <td>(612) 512 76 99</td>
        <td>Smart</td>
        <td>
          <Action>DELETE</Action>
        </td>
      </tr>
    </tbody>

    <style jsx>{`
      table {
        min-width: 1000px;
      }

      table,
      td,
      th {
        border: none;
      }

      td {
        height: 120px;
      }
    `}</style>
  </table>
)

export default Drivers
