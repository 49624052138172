import React, { Fragment } from 'react'
import restLogo from '../../images/foodsby-outline_black.png'
const LOGO_URL = process.env.REACT_APP_IMG_URL + restLogo

const Receipt = ({ data, numOfOrders, DropSymbol }) => {
  let allOrders = []
  if (data !== undefined) {
    for (let i = 0; i < numOfOrders; i++) {
      allOrders.push({ ...data.orders[0] }) // Index of 0 or 1 of orders will be added to allOrders
    }
  }

  return (
    <Fragment>
      {allOrders.map((order, key) => (
        <div key={key} className="receiptContainer">
          <div className="row">
            <div className="col-xs-6 start-xs customerName">{order.name}</div>
            <div className="col-xs-6 end-xs dropSymbol">
              <DropSymbol />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-6 start-xs customrPhone">{order.phone}</div>
            <div className="col-xs-6 end-xs">{order.delivery.time}</div>
          </div>
          <hr />
          <div className="row">
            <div className="col-xs-8 start-xs orderNumber">
              Order # {order.orderId + key}
            </div>
            <div className="col-xs-4 end-xs deliveryLocation">
              <strong>{order.delivery.locationName}</strong>
              <br />
              {order.delivery.street}
              {order.delivery.cityStateZip}
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="menuItem">
              {order.orderItem.name} - ${order.orderItem.price.toFixed(2)}
            </div>
          </div>
          <div className="row">
            <div className="orderDetails">
              {order.orderItem.modifiers.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
            </div>
          </div>
          <div>
            <div className="row specialInstructions">Special Instructions:</div>
            <br />
            <div className="row center-xs">
              <img src={LOGO_URL} height="50" alt="Foodsby Logo" />
            </div>
            <div className="row center-xs deliveredBy">
              Delivered By:&nbsp;
              <span className="restaurantName">{order.restaurantName}</span>
            </div>
          </div>
          <br />
          <style jsx>{`
            .receiptContainer {
              margin-bottom: 32px;
              break-after: page;
            }

            .customerName {
              font-weight: bold;
            }

            .dropSymbol {
              font-size: 32px;
            }

            .deliveredBy {
              margin-top: 10px;
            }

            .restaurantName {
              font-weight: bold;
            }
          `}</style>
        </div>
      ))}
    </Fragment>
  )
}
export default Receipt
