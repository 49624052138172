import React from 'react'
import { Card } from '@foodsby/nutrient'
import { FaCheckCircle } from 'react-icons/fa'

const DriverDeliveryDetail = ({ data, onClick }) => (
  <Card onClick={onClick} className="cursor-pointer">
    <div className="col-xs-10">
      <b>{data.delivery.time}</b> - {data.totalCount} meal
      <br />
      <b>{data.delivery.location}</b>
    </div>
    {data.delivered ? (
      <div className="col-xs-2 check-mark">
        <FaCheckCircle />
      </div>
    ) : null}
    <style jsx>{`
      .check-mark {
        font-size: 36px;
        color: var(--nu-success);
      }
    `}</style>
  </Card>
)

export default DriverDeliveryDetail
