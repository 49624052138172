import React from 'react'
import { Card, Collapsible } from '@foodsby/nutrient'

const OrderDetailCollapsible = ({ order, orderKey }) => {
  const header = (
    <span>
      Order #: {order.orderId + orderKey}&nbsp;&nbsp; Total: $
      {order.orderItem.price.toFixed(2)}
      &nbsp;&nbsp; Meals: 1&nbsp;&nbsp; Refunded: -$
      {order.orderItem.refunded.toFixed(2)}
      &nbsp;&nbsp; Couponed: $0.00
    </span>
  )

  const orderDetails = (
    <div className="order">
      {order.refund && (
        <div className="refund">
          Refund Reason: {order.refund.reason}
          <br />
          Refund Amount: -${order.refund.amount.toFixed(2)}
        </div>
      )}
      <div className="order-details">
        Name: {order.name}&nbsp;&nbsp;&nbsp;Dropoff Time: {order.delivery.time}
        <br />
        <br />
        Order: {order.orderItem.name}
      </div>
      <style jsx>{`
        .order {
          padding: 24px;
        }
        .refund {
          background-color: rgb(235, 56, 71);
          padding: 18px;
          color: white;
          margin-bottom: 12px;
        }
      `}</style>
    </div>
  )
  return (
    <div className="wrapper">
      <Card>
        <Collapsible trigger={header}>{orderDetails}</Collapsible>
      </Card>
      <style jsx>{`
        .wrapper {
          margin: 12px;
          text-align: left;
          width: 97%;
        }

        .inside-collapsible {
          padding: 24px;
        }
      `}</style>
    </div>
  )
}

export default OrderDetailCollapsible
