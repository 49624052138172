import React, { PureComponent } from 'react'
import {
  VIEW_TOP_DELIVERY_EVENT,
  VIEW_BOTTOM_DELIVERY_EVENT,
  VIEW_MIDDLE_DELIVERY_EVENT,
  BOTTOM_DELIVERY_DROPPED_EVENT,
  TOP_DELIVERY_DROPPED_EVENT,
  MIDDLE_DELIVERY_DROPPED_EVENT,
  VIEW_ALL_DELIVERY_EVENT,
  OUT_FOR_DELIVERY_EVENT
} from '../../utils/statemachine'
import { format } from 'date-fns'
import DeliveryDetail, { DeliveryDetailHeaders } from '../common/DeliveryDetail'
import AddDriver from '../common/AddDriver'
import RestaurantActions from '../common/RestaurantActions'
import ReceiptWindow from './ReceiptWindow'
import { Card, Modal } from '@foodsby/nutrient'

import { FaSquareFull, FaCircle, FaStar } from 'react-icons/fa'
const dropSymbolTop = FaSquareFull
const dropSymbolMiddle = FaCircle
const dropSymbolBottom = FaStar

class ManageDelivery extends PureComponent {
  render() {
    const {
      driver,
      bottom,
      top,
      middle,
      passedCutOff,
      outForDelivery,
      cutoffTime,
      triggerEvent,
      triggerDriverEvent,
      toggleTopOrders,
      toggleBottomOrders,
      toggleMiddleOrders,
      toggleAllOrders,
      topOrdersViewed,
      bottomOrdersViewed,
      middleOrdersViewed,
      allOrdersViewed
    } = this.props

    let receiptProps = {
      data: null,
      numOfOrders: 0
    }
    if (topOrdersViewed) {
      receiptProps = {
        data: top,
        numOfOrders: top.totalCount,
        dropSymbol: dropSymbolTop
      }
    } else if (middleOrdersViewed) {
      receiptProps = {
        data: middle,
        numOfOrders: middle.totalCount,
        dropSymbol: dropSymbolMiddle
      }
    } else if (bottomOrdersViewed) {
      receiptProps = {
        data: bottom,
        numOfOrders: bottom.totalCount,
        dropSymbol: dropSymbolBottom
      }
    }

    return (
      <div className="content">
        <div className="wrapper">
          <Card>
            <div className="center-xs">
              <h2>Delivery: {format(new Date(), 'MM/DD/YYYY')}</h2>
            </div>
            <div className="row">
              <div className="col-xs-6 center-xs">
                {driver && driver.name.toUpperCase()}
                &nbsp;&nbsp;&nbsp;
                {driver && driver.number}
              </div>
              <div className="col-xs-6 center-xs">
                <strong>RECEIPT TYPE: </strong>LETTER
              </div>
            </div>
            <br />
            <RestaurantActions
              passedCutOff={passedCutOff}
              cutoffTime={cutoffTime}
              viewAll={() => triggerEvent(VIEW_ALL_DELIVERY_EVENT)}
              handleOutForDelivery={() => triggerEvent(OUT_FOR_DELIVERY_EVENT)}
              outForDelivery={outForDelivery}
            />
            <DeliveryDetailHeaders />
            {top && (
              <DeliveryDetail
                data={top}
                passedCutOff={passedCutOff}
                view={() => triggerEvent(VIEW_TOP_DELIVERY_EVENT)}
                deliver={() => triggerEvent(TOP_DELIVERY_DROPPED_EVENT)}
              />
            )}
            {middle && (
              <DeliveryDetail
                data={middle}
                passedCutOff={passedCutOff}
                view={() => triggerEvent(VIEW_MIDDLE_DELIVERY_EVENT)}
                deliver={() => triggerEvent(MIDDLE_DELIVERY_DROPPED_EVENT)}
              />
            )}
            {bottom && (
              <DeliveryDetail
                data={bottom}
                passedCutOff={passedCutOff}
                view={() => triggerEvent(VIEW_BOTTOM_DELIVERY_EVENT)}
                deliver={() => triggerEvent(BOTTOM_DELIVERY_DROPPED_EVENT)}
              />
            )}
          </Card>
          {!driver && (
            <Modal showCancel={false}>
              <AddDriver submit={triggerDriverEvent} />
            </Modal>
          )}
          {topOrdersViewed ||
          middleOrdersViewed ||
          bottomOrdersViewed ||
          allOrdersViewed ? (
            <ReceiptWindow
              onCancel={
                topOrdersViewed
                  ? toggleTopOrders
                  : middleOrdersViewed
                  ? toggleMiddleOrders
                  : bottomOrdersViewed
                  ? toggleBottomOrders
                  : allOrdersViewed
                  ? toggleAllOrders
                  : null
              }
              allOrdersViewed={allOrdersViewed}
              top={top}
              middle={middle}
              bottom={bottom}
              receiptProps={receiptProps}
              dropSymbolTop={dropSymbolTop}
              dropSymbolMiddle={dropSymbolMiddle}
              dropSymbolBottom={dropSymbolBottom}
            />
          ) : null}
        </div>
        <style jsx>{`
          .content {
            width: 900px;
            margin-left: 24px;
          }
        `}</style>
      </div>
    )
  }
}

export default ManageDelivery
