import React from 'react'
import CalendarDay from './CalendarDay'

const Calendar = () => {
  const orders = [
    { cutoff: '10:45', orders: '', drops: 10, sales: ''}
  ]

  const futureOrders = [
    { cutoff: '11:00', orders: '', drops: 12, sales: '' }
  ]

  return (
    <table>
      <caption>June 2019</caption>
      <thead>
        <tr>
          <th>Sun</th>
          <th>Mon</th>
          <th>Tue</th>
          <th>Wed</th>
          <th>Thu</th>
          <th>Fri</th>
          <th>Sat</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><CalendarDay day={28} disabled /></td>
          <td><CalendarDay day={29} disabled/></td>
          <td><CalendarDay day={30} disabled/></td>
          <td><CalendarDay day={1} /></td>
          <td><CalendarDay day={2} /></td>
          <td><CalendarDay day={3} /></td>
          <td><CalendarDay day={4} /></td>
        </tr>
        <tr>
          <td><CalendarDay day={5} /></td>
          <td><CalendarDay day={6} orders={orders}/></td>
          <td><CalendarDay day={7} orders={orders}/></td>
          <td><CalendarDay day={8} orders={orders}/></td>
          <td><CalendarDay day={9} orders={orders}/></td>
          <td><CalendarDay day={10} orders={orders}/></td>
          <td><CalendarDay day={11} /></td>
        </tr>
        <tr>
          <td><CalendarDay day={12} /></td>
          <td><CalendarDay day={13} orders={orders}/></td>
          <td><CalendarDay day={14} orders={orders}/></td>
          <td><CalendarDay day={15} orders={orders}/></td>
          <td><CalendarDay day={16} orders={orders}/></td>
          <td><CalendarDay day={17} orders={orders}/></td>
          <td><CalendarDay day={18} /></td>
        </tr>
        <tr>
          <td><CalendarDay day={19} /></td>
          <td><CalendarDay day={20} orders={orders}/></td>
          <td className="today"><CalendarDay day={21} orders={orders}/></td>
          <td><CalendarDay day={22} orders={futureOrders} /></td>
          <td><CalendarDay day={23} orders={futureOrders} /></td>
          <td><CalendarDay day={24} orders={futureOrders} /></td>
          <td><CalendarDay day={25} /></td>
        </tr>
        <tr>
          <td><CalendarDay day={26} /></td>
          <td><CalendarDay day={27} orders={futureOrders} /></td>
          <td><CalendarDay day={28} orders={futureOrders} /></td>
          <td><CalendarDay day={29} /></td>
          <td><CalendarDay day={30} /></td>
          <td><CalendarDay day={31} /></td>
          <td><CalendarDay day={1} disabled/></td>
        </tr>
      </tbody>
      <style jsx>{`
        @import '@foodsby/nutrient/src/css/vars.css';

        table {
          min-width: 1000px;
          margin-top: 24px;
          border-collapse: collapse;
        }

        table,
        th,
        td {
          border: 1px solid var(--nu-light-grey);
        }

        .today {
          background-color: rgb(240,111,74);
        }

        caption {
          font-size: 32px;
          margin-bottom: 16px;
        }

        th {
          text-align: center;
        }
      `}</style>
    </table>
  )
}

export default Calendar
