import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { mainRoutes, restaurantRoutes } from '../../routes'
import img from '../../images/goldenspoon.png'
import { AppContext } from '../../context/AppContext'

class Route extends PureComponent {
  render() {
    const { active, children, className, route } = this.props
    return (
      <Link to={route}>
        <button className={active ? `${className} active` : className}>
          {children}
        </button>
        <style jsx>{`
          @import '@foodsby/nutrient/src/css/vars.css';
          .nav-button {
            background-color: white;
            padding: calc(var(--gutter) / 2) var(--gutter);
            border: none;
            cursor: pointer;
            font-size: 28px;
            width: 100%;
            color: var(--nu-secondary);
            text-align: left;
          }
          .active {
            color: var(--nu-primary);
          }
        `}</style>
      </Link>
    )
  }
}

const SideNav = () => (
  <nav>
    <img src={img} alt="Foodsby Logo" />
    <div className="mainRoutes">
      <Route
        route={mainRoutes.RESTAURANT}
        active={window.location.pathname === mainRoutes.RESTAURANT}
        className="nav-button"
      >
        Today
      </Route>
      <Route
        route={restaurantRoutes.MANAGE}
        active={window.location.pathname === restaurantRoutes.MANAGE}
        className="nav-button"
      >
        Manage
      </Route>
      <Route
        route={restaurantRoutes.STORE_INFO}
        active={window.location.pathname === restaurantRoutes.STORE_INFO}
        className="nav-button"
      >
        Store Information
      </Route>
      <Route
        route={restaurantRoutes.MENU}
        active={window.location.pathname === restaurantRoutes.MENU}
        className="nav-button"
      >
        Menu
      </Route>
      <Route
        route={restaurantRoutes.DOCUMENTS}
        active={window.location.pathname === restaurantRoutes.DOCUMENTS}
        className="nav-button"
      >
        Documents
      </Route>
      <Route
        route={restaurantRoutes.STATS}
        active={window.location.pathname === restaurantRoutes.STATS}
        className="nav-button"
      >
        Stats
      </Route>
    </div>
    <style jsx>{`
      @import '@foodsby/nutrient/src/css/vars.css';
      nav {
        min-height: 100%;
        background-color: white;
        padding-top: var(--gutter);
        box-shadow: 10px 10px 20px 8px rgba(20, 20, 20, 0.05);
        min-width: 260px;
        width: 260px;

        & > img {
          padding: var(--gutter);
          width: 100%;
          margin-bottom: 12px;
        }

        & > .mainRoutes {
          display: flex;
          flex-direction: column;
        }
      }
    `}</style>
  </nav>
)

const HOC = () => (
  <AppContext.Consumer>
    {(context) => <SideNav {...context} />}
  </AppContext.Consumer>
)

export default HOC
