import React, { PureComponent, Fragment } from 'react'
import { Card } from '@foodsby/nutrient'

import DetailByBuilding from './DetailByBuilding'

// Constants based on deliveries.js data
const ONE_SALE = 8.5
const TOTAL_ORDERS = 23
const TOTAL_MEALS = 23
const MEAL_CAP = 65
const CUTOFF = '11:00 AM'
const TOTAL_COUPON = 0

class DeliveryDetailTab extends PureComponent {
  calculateTotal = type => {
    const { top, middle, bottom } = this.props
    const allOrders = [...top.orders, ...middle.orders, ...bottom.orders]
    return allOrders.reduce((accumulator, order) => accumulator + order.orderItem[type], 0)
  }

  render() {
    const { top, middle, bottom, driver, date } = this.props
    const totalSales = ONE_SALE * (top.totalCount + middle.totalCount + bottom.totalCount)
    const totalRefund = this.calculateTotal('refunded')

    return (
      <Fragment>
        <div className="wrapper">
          <Card>
            <h3>Driver: {driver && driver.name}</h3>
            <div className="col-xs-6 left-col">
              <table>
                <tbody>
                  <tr>
                    <td>Date</td>
                    <td>{date}</td>
                  </tr>
                  <tr>
                    <td>Cutoff</td>
                    <td>{CUTOFF}</td>
                  </tr>
                  <tr>
                    <td>Sales</td>
                    <td>${totalSales.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td>Total Refunded</td>
                    <td>-${totalRefund.toFixed(2)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-xs-6 right-col">
              <table>
                <tbody>
                  <tr>
                    <td>Total Orders:</td>
                    <td>{TOTAL_ORDERS}</td>
                  </tr>
                  <tr>
                    <td>Total Meals:</td>
                    <td>{TOTAL_MEALS}</td>
                  </tr>
                  <tr>
                    <td>Meal Cap:</td>
                    <td>{MEAL_CAP}</td>
                  </tr>
                  <tr>
                    <td>Total Coupon:</td>
                    <td>${TOTAL_COUPON.toFixed(2)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Card>

          <style jsx global>{`
            .wrapper {
              text-align: center;
              box-shadow: 10px 10px 20px 8px rgba(20, 20, 20, 0.05);
              margin-left: 24px;
              width: 900px;

              & table,
              & td,
              & th {
                border: none;
              }
            }
            .card {
              width: 80%;
            }

            .left-col {
              border-right: 1px solid var(--nu-light-grey);
            }
          `}</style>
        </div>
        <br />
        <DetailByBuilding building={top} />
        <DetailByBuilding building={middle} />
        <DetailByBuilding building={bottom} />
      </Fragment>
    )
  }
}

export default DeliveryDetailTab
