import React, { Component } from 'react'
import { statemachine, STATE_NAME, START_EVENT } from '../utils/statemachine'

export const AppContext = React.createContext()

export default class AppProvider extends Component {
  state = {
    linkClicked: false
  }

  subscribeToStateMachine = () => {
    if (statemachine.initialized) {
      return
    }

    statemachine.on(STATE_NAME, currentState => {
      this.setState({ ...currentState })
    })
    statemachine.handleAction(START_EVENT)
  }

  machineDriverEvent = (EVENT_NAME, name, number) => {
    const data = { name, number }
    statemachine.handleAction(EVENT_NAME, data)
  }

  machineEvent = EVENT_NAME => {
    statemachine.handleAction(EVENT_NAME)
  }

  machineReset = () => {
    statemachine.reset()
    this.setState({ linkClicked: false })
  }

  driverClickLink = () => this.setState({ linkClicked: true })

  render() {
    const { children, ...rest } = this.props
    return (
      <AppContext.Provider
        value={{
          ...rest,
          ...this.state,
          subscribeToStateMachine: this.subscribeToStateMachine,
          machineDriverEvent: this.machineDriverEvent,
          machineEvent: this.machineEvent,
          machineReset: this.machineReset,
          driverClickLink: this.driverClickLink
        }}
      >
        {children}
      </AppContext.Provider>
    )
  }
}
