import React, { Component } from 'react'
import { initTokens, getAccessToken, removeTokens } from '../services/jwt'
import { signIn } from '../services/auth'
import jwtDecode from 'jwt-decode'

export const AuthContext = React.createContext()
const DEFAULT_STATE = {
  logIn: undefined,
  loading: true,
  user: undefined,
  accessToken: undefined,
  refreshToken: undefined
}

export default class AuthProvider extends Component {
  state = DEFAULT_STATE
  async componentDidMount() {
    try {
      initTokens() // create a CrossStorage hub
      let accessToken = getAccessToken() // is user logged in?

      // if not try to get the jwt from other domain
      if (!accessToken) {
        this.setState({ loading: false })
      } else {
        this.getData(accessToken)
      }
    } catch (ex) {
      this.setState({ loading: false, error: ex })
    }
  }

  getData = accessToken => {
    const {
      user_id: userId,
      location_id: locationId,
      user_name: username,
      authorities
    } = jwtDecode(accessToken)
    let checkAuth = JSON.stringify(authorities)
    if (Array.isArray(authorities) && checkAuth.includes('ROLE_FOODSBY_DEMO')) {
      this.setState({
        accessToken,
        user: {
          userId,
          username,
          locationId
        },
        loading: false,
        error: undefined
      })
    } else {
      // LOGOUT and throw error
      removeTokens()
      throw new Error(
        'You do not have permission to access this application. Please log in with an Admin account.'
      )
    }
  }

  login = async data => {
    this.setState({ loading: true })
    try {
      await signIn(data)
      const accessToken = getAccessToken()
      this.getData(accessToken)
    } catch (ex) {
      this.setState({ loading: false, error: ex })
    }
  }

  render() {
    return (
      <AuthContext.Provider
        value={{
          ...this.state,
          logIn: this.login
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    )
  }
}
