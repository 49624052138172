import React, { PureComponent } from 'react'
import { Form, FormField, InputError, Button, Card } from '@foodsby/nutrient'
import AuthProvider, { AuthContext } from '../context/Auth'
import { mainRoutes } from '../routes'
import { Redirect } from 'react-router-dom'
import LoadingSpinner from '../components/common/LoadingSpinner'

export default class Login extends PureComponent {
  render() {
    const { location } = this.props
    return (
      <AuthProvider>
        <AuthContext.Consumer>
          {({ logIn, loading, accessToken, error }) => {
            return accessToken != null ? (
              <Redirect
                to={{
                  pathname: mainRoutes.RESTAURANT,
                  state: { from: location }
                }}
              />
            ) : (
              <div className="wrapper">
                <Card>
                  {loading ? (
                    <LoadingSpinner />
                  ) : (
                    <Form onSubmit={data => logIn(data)}>
                      <h1>Log In</h1>
                      <FormField
                        type="email"
                        placeholder="Email"
                        name="username"
                        required
                      />
                      <FormField
                        type="password"
                        placeholder="Password"
                        name="password"
                        required
                      />
                      <div className="row">
                        <div className="col-xs middle">
                          <Button type="submit">Sign In</Button>
                        </div>
                      </div>
                      <InputError>{error && error.message}</InputError>
                    </Form>
                  )}
                </Card>
                <style jsx>{`
                  .wrapper {
                    max-width: 400px;
                    margin: 0 auto;
                  }
                `}</style>
              </div>
            )
          }}
        </AuthContext.Consumer>
      </AuthProvider>
    )
  }
}
