import React from 'react'
import { Card } from '@foodsby/nutrient'
import { Link } from 'react-router-dom'
import { restaurantRoutes } from '../../routes'

const StatsByDelivery = ({ totals, date }) => (
  <div className="wrapper">
    <Card>
      <div className="content">
        <Link
          to={`${restaurantRoutes.PREV_ORDER_DETAILS}/05-${date &&
            date.getDate()}-2019`}
        >
          View Delivery
        </Link>
        <span>Date: {date && date.toLocaleDateString()}</span>
        <span>Sales: ${totals.TOTAL_SALES.toFixed(2)}</span>
        <span>Total meals: {totals.TOTAL_MEALS}</span>
        <span>Refunded: -${totals.TOTAL_REFUNDED.toFixed(2)}</span>
        <span>Couponed: $0.00</span>
      </div>
    </Card>
    <style jsx>{`
      .wrapper {
        margin-bottom: 12px;
      }

      .content {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    `}</style>
  </div>
)

export default StatsByDelivery
