import React from 'react'
import { Card, Button } from '@foodsby/nutrient'
import Main from '../../layouts/Main'

const Deliveries = ({ driver, showManageDelivery, cutoffTime }) => {
  return (
    <Main>
      <div className="wrapper">
        <h2>Today's Deliveries - {new Date().toLocaleDateString()}</h2>
        <Card className="card">
          <div className="col-xs-6 left-col">
            <table>
              <tbody>
                <tr>
                  <td>Driver</td>
                  <td>{driver ? driver.name.toUpperCase() : 'No Driver Assigned'}</td>
                </tr>
                <tr>
                  <td>Cutoff time:</td>
                  <td>{cutoffTime}</td>
                </tr>
                <tr>
                  <td>First drop time:</td>
                  <td>11:50 AM</td>
                </tr>
                <tr>
                  <td>Total drops:</td>
                  <td>3</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-xs-6 right-col">
            <table>
              <tbody>
                <tr>
                  <td>Meal cap:</td>
                  <td>65</td>
                </tr>
                <tr>
                  <td>Total orders:</td>
                  <td>23</td>
                </tr>
                <tr>
                  <td>Total meals:</td>
                  <td>23</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="bottom">
            <Button onClick={() => showManageDelivery()}>MANAGE DELIVERY</Button>
          </div>
        </Card>
      </div>
      <style jsx global>{`
        .wrapper {
          text-align: center;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          width: 850px;
        }

        .card {
          width: 700px;
        }

        .left-col {
          border-right: 1px solid var(--nu-light-grey);
        }

        .wrapper table,
        .wrapper td,
        .wrapper th {
          border: none;
        }

        .bottom {
          border-top: 1px solid var(--nu-light-grey);
          padding-top: 15px;
          margin-top: 15px;
        }
      `}</style>
    </Main>
  )
}

export default Deliveries
