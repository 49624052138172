import React from 'react'
import { Card } from '@foodsby/nutrient'

const DeliveryRules = () => (
  <div className="wrapper row">
    <div className="col-xs-6">
      <Card>
        <h3>Schedulable Days</h3>
        <table>
          <tbody>
            <tr>
              <td>Monday</td>
              <td>1 deliveries</td>
            </tr>
            <tr>
              <td>Tuesday</td>
              <td>1 deliveries</td>
            </tr>
            <tr>
              <td>Wednesday</td>
              <td>1 deliveries</td>
            </tr>
            <tr>
              <td>Thursday</td>
              <td>1 deliveries</td>
            </tr>
            <tr>
              <td>Friday</td>
              <td>1 deliveries</td>
            </tr>
            <tr>
              <td>Saturday</td>
              <td>1 deliveries</td>
            </tr>
            <tr>
              <td>Sunday</td>
              <td>1 deliveries</td>
            </tr>
          </tbody>
        </table>
      </Card>
    </div>
    <div className="col-xs-6">
      <Card>
        <h3>Default Delivery Rules</h3>
        <table>
          <tbody>
            <tr>
              <td>First delivery date:</td>
              <td>8/28/2013</td>
            </tr>
            <tr>
              <td>Delivery Radius:</td>
              <td>10 minutes</td>
            </tr>
            <tr>
              <td>Default cut-off time:</td>
              <td>10:45</td>
            </tr>
            <tr>
              <td>Default first dropoff time:</td>
              <td>12:00</td>
            </tr>
            <tr>
              <td>Max drops:</td>
              <td>4</td>
            </tr>
            <tr>
              <td>Max meals:</td>
              <td>65</td>
            </tr>
          </tbody>
        </table>
      </Card>
    </div>
    <style jsx>{`
      .wrapper {
        min-width: 1000px;
      }

      table,
      td,
      th {
        border: none;
      }

      td {
        height: 40px;
      }
    `}</style>
  </div>
)

export default DeliveryRules
