import React, { PureComponent, Fragment } from 'react'
import { AppContext } from '../../context/AppContext'
//Tabs package
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'

import RestaurantSideNav from '../../components/restaurant/RestaurantSideNav'
import DeliveryDetailTab from '../../components/restaurant/DeliveryDetailTab'
import DirectionsTab from '../../components/restaurant/DirectionsTab'

class PreviousOrderDetails extends PureComponent {
  constructor(props, context) {
    super(props, context)
    this.props.subscribeToStateMachine()
  }

  render() {
    const { top, middle, bottom, driver } = this.props
    const { date } = this.props.match.params
    return (
      <Fragment>
        <div className="outer-wrapper">
          <RestaurantSideNav />
          <div className="content">
            <h3>{date}</h3>
            <Tabs selectedTabClassName="selected-tab" className="tabs">
              <TabList className="tab-list">
                <Tab>Delivery Detail</Tab>
                <Tab>Directions</Tab>
              </TabList>
              <TabPanel>
                {top && middle && bottom && (
                  <DeliveryDetailTab
                    top={top}
                    middle={middle}
                    bottom={bottom}
                    driver={driver}
                    date={date}
                  />
                )}
              </TabPanel>
              <TabPanel>
                <DirectionsTab />
              </TabPanel>
            </Tabs>
          </div>
        </div>
        <style jsx global>{`
          .outer-wrapper {
            display: flex;
            flex-direction: row;
            height: 100%;
          }

          .content {
            width: 100%;
            margin-left: 24px;
            flex-direction: column;
          }

          .selected-tab {
            color: var(--nu-secondary);
            border-bottom: 2px solid var(--nu-secondary);
          }
        `}</style>
      </Fragment>
    )
  }
}

const HOC = props => (
  <AppContext.Consumer>
    {context => <PreviousOrderDetails {...props} {...context} />}
  </AppContext.Consumer>
)

export default HOC
