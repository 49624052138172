import React, { PureComponent, Fragment } from 'react'
// Tabs package
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import RestaurantSideNav from '../../components/restaurant/RestaurantSideNav'
import PdfTab from '../../components/restaurant/PdfTab'
import onboarding from '../../pdfs/RESTAURANT-INFO-ONBOARDING.pdf'
import expectations from '../../pdfs/RESTAURANT-INFO-EXPECTATIONS.pdf'
import refund_policy from '../../pdfs/RESTAURANT-INFO-REFUND.pdf'
import standard_service from '../../pdfs/RESTAURANT-STANDARD-SERVICE-AGREEMENT.pdf'
import resale_cert from '../../pdfs/RESTAURANT-INFO-RESALE-CERT.pdf'
import virginia_resale_cert from '../../pdfs/RESTAURANT-INFO-RESALE-CERT-VIRGINIA.pdf'

class Documents extends PureComponent {
  render() {
    return (
      <Fragment>
        <div className="outer-wrapper">
          <RestaurantSideNav />
          <div className="content">
            <h2>Documents</h2>
            <Tabs selectedTabClassName="selected-tab" className="tabs">
              <TabList className="tab-list">
                <Tab>ONBOARDING</Tab>
                <Tab>EXPECTATIONS</Tab>
                <Tab>REFUND POLICY</Tab>
                <Tab>STANDARD SERVICE AGREEMENT</Tab>
                <Tab>EXEMPTION/RESALE CERTIFICATION</Tab>
                <Tab>VIRGINIA: EXEMPTIONS/RESALE CERTIFICATE</Tab>
              </TabList>
              <TabPanel><PdfTab file={onboarding} /></TabPanel>
              <TabPanel><PdfTab file={expectations} /></TabPanel>
              <TabPanel><PdfTab file={refund_policy} /></TabPanel>
              <TabPanel><PdfTab file={standard_service} /></TabPanel>
              <TabPanel><PdfTab file={resale_cert} /></TabPanel>
              <TabPanel><PdfTab file={virginia_resale_cert} /></TabPanel>
            </Tabs>
          </div>
        </div>
        <style jsx global>{`
          .outer-wrapper {
            display: flex;
            flex-direction: row;
            height: 100%;
            width: 1200px;
          }

          .content {
            width: 1000px;
            margin-left: 24px;
          }

          .tabs {
            background-color: white;
            padding: 24px;
            box-shadow: 15px 15px 15px 15px rgba(20, 20, 20, 0.05);
          }

          .tab-list {
            overflow: auto;
            white-space: nowrap;
          }

          .selected-tab {
            color: var(--nu-secondary);
            border-bottom: 2px solid var(--nu-secondary);
          }
        `}</style>
      </Fragment>
    )
  }
}

export default Documents
