import React from 'react'
import { Card } from '@foodsby/nutrient'

const ContactInfo = () => (
  <div className="wrapper">
    <Card>
      <h3>Contact Information</h3>
      <div className="col-xs-6 left-col">
        <table>
          <tbody>
            <tr>
              <td>Phone:</td>
              <td>(612) 999-9999</td>
            </tr>
            <tr>
              <td>Email:</td>
              <td>info@goldenspoon.com</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="col-xs-6 right-col">
        <table>
          <tbody>
            <tr>
              <td>Cell:</td>
              <td>None</td>
            </tr>
            <tr>
              <td>Fax:</td>
              <td>None</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Card>
    <style jsx global>{`
      .wrapper {
        width: 1000px;
        text-align: center;
        & table,
        & td,
        & th {
          border: none;
        }
      }
      .card {
        width: 80%;
      }

      .left-col {
        border-right: 1px solid var(--nu-light-grey);
      }
    `}</style>
  </div>
)

export default ContactInfo
