import React, { PureComponent, Fragment } from 'react'
import { AppContext } from '../../context/AppContext'
//Tabs package
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'

import RestaurantSideNav from '../../components/restaurant/RestaurantSideNav'
import ManageDelivery from '../../components/restaurant/ManageDelivery'
import DeliveryDetailTab from '../../components/restaurant/DeliveryDetailTab'
import DirectionsTab from '../../components/restaurant/DirectionsTab'
import Deliveries from '../../components/restaurant/Deliveries'

import DemoStateUpdates from '../../components/common/DemoStateUpdates'

// Home page to Restaurant
class Today extends PureComponent {
  state = {
    topOrdersViewed: false,
    middleOrdersViewed: false,
    bottomOrdersViewed: false,
    allOrdersViewed: false,
    manageDeliveryVisible: false,
    allOrders: undefined
  }

  constructor(props, context) {
    super(props, context)
    this.props.subscribeToStateMachine()
  }

  componentWillUnmount() {
    this.setState({ manageDeliveryVisible: false })
  }

  showManageDelivery = () => {
    this.setState({ manageDeliveryVisible: true })
  }

  toggleModal = action => {
    this.setState({ [action]: !this.state[action] })
  }

  toggleTopOrders = () => this.toggleModal('topOrdersViewed')
  toggleMiddleOrders = () => this.toggleModal('middleOrdersViewed')
  toggleBottomOrders = () => this.toggleModal('bottomOrdersViewed')
  toggleAllOrders = () => this.toggleModal('allOrdersViewed')

  triggerDriverEvent = ({ EVENT_NAME, name, number }) => {
    this.props.machineDriverEvent(EVENT_NAME, name, number)
  }

  triggerEvent = EVENT_NAME => {
    const { top, middle, bottom } = this.props
    if (EVENT_NAME === 'VIEW_TOP_DELIVERY') {
      this.toggleTopOrders()
    } else if (EVENT_NAME === 'VIEW_MIDDLE_DELIVERY') {
      this.toggleMiddleOrders()
    } else if (EVENT_NAME === 'VIEW_BOTTOM_DELIVERY') {
      this.toggleBottomOrders()
    } else if (EVENT_NAME === 'VIEW_ALL_DELIVERY') {
      const allOrders = { orders: [...top.orders, ...middle.orders, ...bottom.orders] }
      this.setState({ allOrders: allOrders })
      this.toggleAllOrders()
    }
    this.props.machineEvent(EVENT_NAME)
  }

  render() {
    const { nextState, machineReset, top, bottom, middle, driver, cutoffTime } = this.props
    const {
      manageDeliveryVisible,
      topOrdersViewed,
      bottomOrdersViewed,
      middleOrdersViewed,
      allOrdersViewed,
      allOrders
    } = this.state
    return (
      <Fragment>
        <DemoStateUpdates
          nextState={nextState}
          triggerEvent={this.triggerEvent}
          reset={machineReset}
        />
        <div className="outer-wrapper">
          <RestaurantSideNav />
          <div className="inner-wrapper">
            {manageDeliveryVisible ? (
              <Tabs selectedTabClassName="selected-tab" className="tabs">
                <TabList className="tab-list">
                  <Tab>Manage Delivery</Tab>
                  <Tab>Delivery Detail</Tab>
                  <Tab>Directions</Tab>
                </TabList>
                <TabPanel>
                  <ManageDelivery
                    {...this.props}
                    triggerEvent={this.triggerEvent}
                    triggerDriverEvent={this.triggerDriverEvent}
                    topOrdersViewed={topOrdersViewed}
                    middleOrdersViewed={middleOrdersViewed}
                    bottomOrdersViewed={bottomOrdersViewed}
                    allOrdersViewed={allOrdersViewed}
                    allOrders={allOrders}
                    toggleTopOrders={this.toggleTopOrders}
                    toggleMiddleOrders={this.toggleMiddleOrders}
                    toggleBottomOrders={this.toggleBottomOrders}
                    toggleAllOrders={this.toggleAllOrders}
                  />
                </TabPanel>
                <TabPanel>
                  <DeliveryDetailTab
                    top={top}
                    middle={middle}
                    bottom={bottom}
                    driver={driver}
                    date={new Date().toLocaleDateString()}
                  />
                </TabPanel>
                <TabPanel>
                  <DirectionsTab />
                </TabPanel>
              </Tabs>
            ) : (
              <Deliveries
                driver={this.props.driver}
                showManageDelivery={this.showManageDelivery}
                cutoffTime={cutoffTime}
              />
            )}
          </div>
        </div>
        <style jsx global>{`
          .outer-wrapper {
            display: flex;
            flex-direction: row;
            height: 100%;
          }

          .inner-wrapper {
            padding-left: 15px;
            width: 1000px;
            flex-direction: column;
          }

          .selected-tab {
            color: var(--nu-secondary);
            border-bottom: 2px solid var(--nu-secondary);
          }
        `}</style>
      </Fragment>
    )
  }
}

const HOC = props => (
  <AppContext.Consumer>
    {context => <Today {...props} {...context} />}
  </AppContext.Consumer>
)

export default HOC
