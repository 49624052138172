export const mainRoutes = {
  HOME: '/',
  RESTAURANT: '/restaurant',
  DRIVER: '/driver'
}

export const restaurantRoutes = {
  TODAY: '/restaurant',
  MANAGE: '/restaurant/manage',
  STORE_INFO: '/restaurant/store_info',
  MENU: '/restaurant/menu',
  DOCUMENTS: '/restaurant/documents',
  STATS: '/restaurants/stats',
  PREV_ORDER_DETAILS: '/restaurant/manage/prev'
}
