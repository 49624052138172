import React, { PureComponent, Fragment } from 'react'
import { AppContext } from '../../context/AppContext'

import RestaurantSideNav from '../../components/restaurant/RestaurantSideNav'
import StatsHeader from '../../components/restaurant/StatsHeader'
import StatsByDelivery from '../../components/restaurant/StatsByDelivery'

// Contstans for stats page simulation (they all are by day)
const BY_DAY = {
  TOTAL_SALES: 195.5,
  TOTAL_REFUNDED: 10.5,
  TOTAL_MEALS: 23,
  TOTAL_REFUNDS: 1,
  TOTAL_DELIVERIES: 23,
  BEST_DELIVERY: 195.5
}

class Stats extends PureComponent {
  state = {
    dates: []
  }

  constructor(props, context) {
    super(props, context)
    this.props.subscribeToStateMachine()
  }

  handleStatsDatePicker = async (startDate, endDate) => {
    await this.setDates(startDate, endDate) // awaits for setState function to resolve
  }

  // sets the dates between two given dates as an array
  setDates = (startDate, endDate) => {
    let dateArray = []
    let currentDate = new Date(startDate.getTime())
    while (currentDate <= endDate) {
      dateArray.push(new Date(currentDate))
      currentDate.setDate(currentDate.getDate() + 1) // increment current date to next day
    }
    this.setState({ dates: dateArray })
  }

  render() {
    const { dates } = this.state

    const totalSales = BY_DAY.TOTAL_SALES * dates.length
    const totalRefunded = BY_DAY.TOTAL_REFUNDED * dates.length
    const totalDeliveries = BY_DAY.TOTAL_DELIVERIES * dates.length
    const totalRefunds = BY_DAY.TOTAL_REFUNDS * dates.length
    const totalMeals = BY_DAY.TOTAL_MEALS * dates.length

    return (
      <Fragment>
        <div className="outer-wrapper">
          <RestaurantSideNav />
          <div className="content">
            <h2>Stats</h2>
            <StatsHeader
              submit={this.handleStatsDatePicker}
              totalSales={totalSales}
              totalRefunded={totalRefunded}
              totalDeliveries={totalDeliveries}
              totalRefunds={totalRefunds}
              totalMeals={totalMeals}
              bestDay={BY_DAY.BEST_DELIVERY}
            />
            <br />
            {dates &&
              dates.map((date, key) => (
                <StatsByDelivery totals={BY_DAY} date={date} key={key} />
              ))}
          </div>
        </div>
        <style jsx>{`
          .outer-wrapper {
            display: flex;
            flex-direction: row;
            height: 100%;
          }

          .content {
            width: 100%;
            margin-left: 24px;
          }
        `}</style>
      </Fragment>
    )
  }
}

const HOC = props => (
  <AppContext.Consumer>
    {context => <Stats {...props} {...context} />}
  </AppContext.Consumer>
)

export default HOC
