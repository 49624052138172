import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import AuthProvider, { AuthContext } from '../context/Auth'
import { mainRoutes } from '../routes'
import LoadingSpinner from '../components/common/LoadingSpinner'

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <AuthProvider>
        <AuthContext.Consumer>
          {({ loading, accessToken }) =>
            loading ? (
              <LoadingSpinner />
            ) : accessToken != null ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{
                  pathname: mainRoutes.HOME,
                  state: { from: props.location }
                }}
              />
            )
          }
        </AuthContext.Consumer>
      </AuthProvider>
    )}
  />
)
export default PrivateRoute
