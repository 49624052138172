import React, { PureComponent, Fragment } from 'react'
import { Card } from '@foodsby/nutrient'
import RestaurantSideNav from '../../components/restaurant/RestaurantSideNav'
import img from '../../images/menu_ss.png'

class Menu extends PureComponent {
  render() {
    return (
      <Fragment>
        <div className="outer-wrapper">
          <RestaurantSideNav />
          <div className="content">
            <Card className="card">
              <h2>Menu Review</h2>
              <p>
                Email all menu changes, updates, and deletions to
                support@foodsby.com
              </p>
            </Card>
            <br />
            <img alt="Demo Menu" src={img} />
          </div>
        </div>
        <style jsx global>{`
          .outer-wrapper {
            display: flex;
            flex-direction: row;
            height: 100%;
          }

          .content {
            width: 100%;
            margin-left: 24px;
            margin-top: 32px;

            & > img {
              width: 1000px;
            }
          }

          .card {
            text-align: center;
            width: 1000px;

            & > h2 {
              color: var(--nu-primary);
            }
          }
        `}</style>
      </Fragment>
    )
  }
}

export default Menu
