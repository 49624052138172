import React, { PureComponent, Fragment } from 'react'
import RestaurantSideNav from '../../components/restaurant/RestaurantSideNav'
import LocationInfo from '../../components/restaurant/LocationInfo'
import ContactInfo from '../../components/restaurant/ContactInfo'

class StoreInfo extends PureComponent {
  render() {
    return (
      <Fragment>
        <div className="outer-wrapper">
          <RestaurantSideNav />
          <div className="content">
            <h2>Store Information</h2>
            <LocationInfo />
            <br />
            <ContactInfo />
          </div>
        </div>
        <style jsx>{`
          .outer-wrapper {
            display: flex;
            flex-direction: row;
            height: 100%;
          }

          .content {
            width: 100%;
            margin-left: 24px;
            flex-direction: column;
          }
        `}</style>
      </Fragment>
    )
  }
}

export default StoreInfo
