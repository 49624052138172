import axios from 'axios'
import { getAccessToken, isAccessTokenExpired } from '../services/jwt'
import { refreshToken } from '../services/auth'

const BASE_URL = process.env.REACT_APP_BASE_URL

/* Error messages */
export const BAD_CREDS = 'Invalid username or password.'
export const SERVICE_UNAVAILABLE =
  'There was an error contacting our system. Please try again in a moment.'
export const NOT_FOUND = 'The requested resource was not found.'
export const INVALID_TOKEN_ERROR =
  'Your access token is either invalid or expired.'
export const GENERIC_ERROR = 'An error occurred.'
export const INVALID_GRANT_TYPE = 'Invalid grant type.'

/* Error statuses from services */
export const INVALID_GRANT = 'invalid_grant'
export const UNAUTHORIZED = 'unauthorized'
export const INVALID_TOKEN = 'invalid_token'
export const ACCESS_TOKEN_EXPIRED = 'Access token expired'
export const BAD_CREDENTIALS = 'Bad credentials'

const MAX_ATTEMPTS = 3

const badCreds = data => data && data.error === UNAUTHORIZED
const invalidGrant = data => data && data.error === INVALID_GRANT
const invalidToken = data => data && data.error === INVALID_TOKEN
const badCredentials = data =>
  data && data.error_description === BAD_CREDENTIALS
const expiredToken = data =>
  data &&
  data.error_description &&
  ~data.error_description.indexOf(ACCESS_TOKEN_EXPIRED)
const formatMessage = data => {
  if (data) {
    if (data.message) {
      return data.message
    } else if (data.error_description) {
      return data.error_description
    } else if (Array.isArray(data.validationErrors)) {
      return data.validationErrors[0].message
    }
  }
  return GENERIC_ERROR
}

const api = {
  get: (endpoint, body, config) => baseApi(endpoint, 'get', body, config),
  post: (endpoint, body, config) => baseApi(endpoint, 'post', body, config),
  patch: (endpoint, body, config) => baseApi(endpoint, 'patch', body, config),
  delete: (endpoint, body, config) => baseApi(endpoint, 'delete', body, config),
  put: (endpoint, body, config) => baseApi(endpoint, 'put', body, config)
}
const baseApi = async (
  endpoint,
  method,
  body,
  configOpts = {},
  attempts = 0
) => {
  let config = {
    baseURL: BASE_URL,
    method: method,
    url: endpoint,
    data: body,
    withCredentials: true,
    ...configOpts
  }
  if (
    isAccessTokenExpired() &&
    config.auth == null &&
    attempts <= MAX_ATTEMPTS
  ) {
    await refreshToken()
  }

  // pull token from local storage
  const token = getAccessToken()
  if (token) {
    config['headers'] = {
      Authorization: `Bearer ${token}`
    }
  }

  return axios(config)
    .then(response => {
      attempts = 0
      return response.data
    })
    .catch(error => {
      console.log(error)
      const {
        response: { status, data } = {
          status: undefined,
          data: undefined
        }
      } = error
      if (status) {
        switch (status) {
          case 400:
            if (invalidGrant(data)) {
              if (badCredentials(data)) {
                throw new Error(BAD_CREDS)
              } else {
                throw new Error(INVALID_GRANT_TYPE)
              }
            } else {
              throw new Error(formatMessage(data))
            }
          case 401:
            if (badCreds(data)) {
              throw new Error(BAD_CREDS)
            } else if (invalidToken(data)) {
              if (expiredToken(data) && attempts <= MAX_ATTEMPTS) {
                return baseApi(endpoint, method, body, configOpts, ++attempts)
              } else {
                throw new Error(INVALID_TOKEN_ERROR)
              }
            } else {
              throw new Error(formatMessage(data))
            }
          case 404:
            throw new Error(NOT_FOUND)
          case 503:
            throw Error(SERVICE_UNAVAILABLE)
          default:
            throw new Error(formatMessage(data))
        }
      } else {
        // this means NO RESPONSE
        throw Error(SERVICE_UNAVAILABLE)
      }
    })
}

export default api
